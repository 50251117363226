import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import template_activities from '../../assets/xlsx/Climate Toolbox Aktivitätsbasierte Datenfelder.xlsx';
import template_sector_based from '../../assets/xlsx/Climate Toolbox Sektorbasierte Datenfelder Template.xlsx';
import info_sector_based from '../../assets/pdf/Climate Toolbox Sektorbasierte Datenfelder.pdf';
import "./climate_toolbox.css";


// const infoSheets = [['Sektorbasierte Datenfelder Information', info_sector_based]];
// const templates = [['Aktivitätsbasierte Datenfelder', template_activities], 
// ['Sektorbasierte Datenfelder Template', template_sector_based]];

const infoSheets = [
  {'label':'Sektorbasierte Datenfelder Information', 'url':info_sector_based}];
// const templates = [['Loanbook Template - Consumer', templateconsumer], ['Loanbook Template - SME', templatesme], ['Portfolio Template - ECS', template_ecs], ['Cash Flows Template', template_cash_flows]];

const templates = [
  {'label':'Aktivitätsbasierte Datenfelder', 'url':template_activities}, 
  {'label':'Sektorbasierte Datenfelder Template','url':template_sector_based}];


function ClimateToolboxSlidein() {
  const menu_template = useRef(null);
  const menu_infoSheet = useRef(null);

  return (
    <div className='climate_grey_out'>
      <p className='text6'>Werfen Sie einen Blick auf unsere Infoblätter oder Vorlagen.</p>
      <div className='pad'>
      <Menu model={templates} popup ref={menu_template} id="popup_menu_templates" />
      <Button label="Templates" icon="pi pi-bars" onClick={(event) => menu_template.current.toggle(event)} aria-controls="popup_menu_templates" aria-haspopup  className="p-button-raised p-button-rounded p-button-sm"/>
      <span>  </span>
      <Menu model={infoSheets} popup ref={menu_infoSheet} id="popup_menu" />
      <Button label="Informationsblatt" icon="pi pi-bars" onClick={(event) => menu_infoSheet.current.toggle(event)} aria-controls="popup_menu" aria-haspopup className="p-button-raised p-button-rounded p-button-sm" />
      </div>
    </div>
  );

};

export default ClimateToolboxSlidein;
import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie'
import { Amplify } from "aws-amplify";

import LogIn from './components/authentication/Login'
import Spinner from './components/loader';
import NavBar from './components/navigation/NavigationBar';
import FileUpload from './components/fileupload/FileUpload';
import TrackFile from './components/filetracking/FileTracking';
import Analytics from './components/analytics/Analytics';
import ClimateToolbox from './components/climate_toolbox/ClimateToolbox';
import Monitoring from './components/monitoring/Monitoring';
import LogOut from './components/authentication/Logout';
import { AuthProvider } from './components/authentication/Authprovider';
import { check_analytics_access, check_ecs_access, check_admin_access, check_abs_access} from './helpers/AccessHelper';
import cognito_config from './cognito-config.json'
import env from './env.json'
import "./main.css";
import "./exaloan-primereact-theme.css"
import { ProtectedRoute } from "./components/authentication/ProtectedRoute";
import { useLocalStorage } from "./helpers/useLocalStorage";
import { Platforms } from "./components/platforms/Platforms";
import { PlatformConfiguration } from "./components/platforms/PlatformConfiguration";
import { PlatformUsers } from "./components/platforms/Users/PlatformUsers";
import  ABSCalculator from "./components/abs_calculator/ABSCalculator"
import PlatformDashboard from "./components/platforms/Dashboard/Dashboard";
import PlatformOverviewDetails from "./components/platforms/Overview/PlatformOverviewDetails";

var DEBUG = env.DEBUG;
if (!DEBUG) {
  if (!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info"];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () { };
  }
}

Amplify.configure({
  aws_cognito_region: cognito_config.region,
  aws_user_pools_id: cognito_config.pool_id,
  aws_user_pools_web_client_id: cognito_config.web_client_id,
});


export const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useLocalStorage("user", null);
  const [groups, setGroups] = useLocalStorage("groups", null);
  const [platform, setPlatform] = useLocalStorage("platform", null);
  const [platform_config, setPlatformConfig] = useLocalStorage("platform_config", null);

  return (

    <div style={{ background: "rgba(165, 164, 164, 0.2)" }} className='main'>
      <script src="http://localhost:8097"></script>
      <nav className="top">
        <NavBar />
      </nav>
      <BrowserRouter>
        <AuthProvider>
          {loading === true ? <div className="loading"><Spinner /> </div> : ""}
          <Routes>
            <Route path="/login" element={<LogIn setLoading={setLoading} setGroups={setGroups} />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/" exact={true} element={<FileUpload setLoading={setLoading} />} />
              <Route path="/filetracking" element={<TrackFile setLoading={setLoading} />} />
              <Route path="/logout" element={<LogOut setLoading={setLoading} />} />
              {check_analytics_access(groups) && <Route path="/analytics" element={<Analytics />} />}
              {check_ecs_access(groups) && <Route path="/climate-toolbox" element={<ClimateToolbox setLoading={setLoading} />} />}
              {check_admin_access(groups) && <Route path="/admin" element={<Monitoring setLoading={setLoading} />} />}
              {check_abs_access(groups) && <Route path="/abs" element={<ABSCalculator />} />}
              {check_admin_access(groups) && <Route path="platforms" element={<Platforms setLoading={setLoading} />} />}
              {check_admin_access(groups) && <Route path="dashboard" element={<PlatformDashboard setLoading={setLoading}/>} />}
              {check_admin_access(groups) && <Route path="dashboard/:platformName" element={<PlatformOverviewDetails setLoading={setLoading}/>} />}
              {check_admin_access(groups) && <Route path="platforms/:platformName"  element={<PlatformConfiguration  setLoading={setLoading}/> } />}
              {check_admin_access(groups) && <Route path="platforms/:platformName/users"  element={<PlatformUsers  setLoading={setLoading}/> } />}
              {/* {check_admin_access(groups) && <Route path="overview" element={<Overview setLoading={setLoading} />} />} */}
              
            </Route>
            <Route path="*" render={() => <Navigate to="/" />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>

  );
};

export default App;

import React, { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { useParams } from 'react-router-dom';
import axiosWithAuth from '../../helpers/AxiosHelper';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';
import _, { join as array_join, has, isNil } from 'lodash';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../../hooks/useAuth';
import { ConfirmPopup } from 'primereact/confirmpopup';
const downloadsTemplate = (rowData) => {
    return <span >{array_join(rowData.downloads, ',')}</span>;
}

const group_options = [
    { 'label': 'Admin', 'value': 'Admin' },
    { 'label': 'ECS', 'value': 'ECS' },
    { 'label': 'Analytics', 'value': 'Analytics' }
]

const platform_analytics_columns = [
    {
        header: 'Tab Title',
        field: 'title'
    },
    {
        header: 'IFrame Title',
        field: 'iframe_title'
    },
    {
        header: 'PowerBI URL',
        field: 'url',
    },
    {
        header: 'Groups',
        field: 'group',
    },
    {
        header: 'Downloads',
        field: 'downloads',
        body: downloadsTemplate
    }
]

const emptyAnalyticsTab = {
    id: null,
    title: '',
    iframe_title: '',
    url: '',
    group: '',
    downloads: []
}

export const PlatformConfiguration = (props) => {
    const [visible, setVisible] = useState(false);
    const [didAnalyticsChange,setdidAnalyticsChange] = useState(false)

    const navigate = useNavigate();

    const { platform,updatePlatformConfig } = useAuth()

    const { platformName } = useParams()

    const data_ypes_options = [
        { 'name': 'SME', 'value': 'SME' },
        { 'name': 'Consumer', 'value': 'CONSUMER' },
        { 'name': 'ECS', 'value': 'ECS' },
        { 'name': 'Cash Flows', 'value': 'CASH_FLOWS' },
        { 'name': 'Opportunities', 'value': 'OPPORTUNITIES' },
        { 'name': 'Loan Applications', 'value': 'APPLICATIONS' },
    ]

    const [platformConfig, setPlatformConfig] = useState({
        validate: false,
        spro_upload: false,
        map_data: false,
        data_types: [],
        platform_analytics: [],
        platform: '',
        _key: ''
    })
    const [platformAnalyticsSingle, setPlatformAnalyticsSingle] = useState(emptyAnalyticsTab);
    const [analyticsDialog, setAnalyticsDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // const [showMessage, setShowMessage] = useState(false);
    // const [formData, setFormData] = useState({});

    useEffect(() => {
        axiosWithAuth.get(`/platforms/${platformName}`).then((response) => {
            let data = response.data[0];
            console.log(data);
            setPlatformConfig({
                validate: data['validate'],
                spro_upload: data['spro_upload'],
                map_data: data['map_data'],
                data_types: data['data_types'],
                platform_analytics: data['platform_analytics'],
                platform: data['platform'],
                _key: data['_key']
            });
            console.log(platformConfig)
        }).catch((err) => {
            alert(err);
        })
        console.log(platformConfig)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            validate: platformConfig.validate,
            spro_upload: platformConfig.spro_upload,
            map_data: platformConfig.map_data,
            data_types: platformConfig.data_types,
            platform_analytics: platformConfig.platform_analytics
        },
        validationSchema: Yup.object({
            validate: Yup.bool().required('Required').default(true),
            spro_upload: Yup.bool().required('Required').default(false),
            map_data: Yup.bool().required('Required').default(false),
            data_types: Yup.array().required('Required')
        }),
        onSubmit: (data) => {
            props.setLoading(true);

            console.log('Submitting data for some reason');
            data['platform'] = platformConfig.platform;
            data['_key'] = platformConfig._key;
            // console.log(data);

            axiosWithAuth.post(`/platforms/${platformName}`, data).then((response) => {
                let updated_data = response.data.data;
                console.log(response);
                setPlatformConfig(updated_data)
                if (platform === platformName){
                updatePlatformConfig(updated_data)
                }
                
            }).catch((err) => {
                alert(err);
            }).finally(() => {
                props.setLoading(false);
            })
            // setFormData(data);
            setdidAnalyticsChange(false)
            // setShowMessage(true);
            formik.resetForm();
        },
        enableReinitialize: true
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dynamicColumns = platform_analytics_columns.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} editor={col.editor} style={col.style} />
    })

    const editAnalyticsTab = (platform_analytics_row) => {
        setPlatformAnalyticsSingle({ ...platform_analytics_row });
        setSubmitted(false);
        setAnalyticsDialog(true);
    }

    const isBlank = function(value) {
        return _.isEmpty(value) || _.isNil(value)
    }

    const validateAnalyticsData = (platform_analytics_single) => {
        if (isBlank(platformAnalyticsSingle.title)||isBlank(platformAnalyticsSingle.iframe_title)||isBlank(platformAnalyticsSingle.url)||isBlank(platformAnalyticsSingle.group))
            return false;
        return true;
    }

    const onAddOrEditAnalyticsTab = () => {
        setSubmitted(true);
        console.log(formik.values.platform_analytics);
        let _platform_analytics_updated =  formik.values.platform_analytics;
        let _platformAnalyticsSingle = platformAnalyticsSingle;
        console.log(_platformAnalyticsSingle);
        console.log(_platform_analytics_updated);
        if (validateAnalyticsData(_platformAnalyticsSingle)) {

            if (has(platformAnalyticsSingle, 'id') && !isNil(platformAnalyticsSingle.id)) {
                console.log('InsideThisss')
                let index = -1;
                for (let i = 0; i < _platform_analytics_updated.length; i++) {
                    if (_platform_analytics_updated[i].id === _platformAnalyticsSingle.id) {
                        index = i;
                        break;
                    }
                }
                console.log(_.isEqual(_platform_analytics_updated[index],_platformAnalyticsSingle))
                _platform_analytics_updated[index] = _platformAnalyticsSingle;
            } else {
                console.log('InsideThis2')
                _platformAnalyticsSingle.id = createId();
                _platform_analytics_updated.push(_platformAnalyticsSingle);
            }
            console.log(_platform_analytics_updated)
            formik.setFieldValue('platform_analytics', _platform_analytics_updated).then((daz) => {formik.setFieldTouched('platform_analytics',true);setdidAnalyticsChange(true);}).catch((err) => console.log(`catch:${err}`));
            setTimeout(() => {
                console.log("D1");
            }, "1000")
            console.log(formik)
            hideDialog();

        }

    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 8; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }


    const hideDialog = () => {
        setPlatformAnalyticsSingle(emptyAnalyticsTab);
        setSubmitted(false);
        setAnalyticsDialog(false);
    }
    const analyticsDialogFooter = (
        <React.Fragment>
            <Button type="button" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={onAddOrEditAnalyticsTab} />
        </React.Fragment>
    );

    const openNew = () => {
        setPlatformAnalyticsSingle(emptyAnalyticsTab);
        setSubmitted(false);
        setAnalyticsDialog(true);
    }

    // Delete Dialog Stuff

    const onDeleteAnalyticsTab = () => {
        let _platform_analytics_updated = formik.values.platform_analytics.filter(val => val.id !== platformAnalyticsSingle.id);
        setPlatformAnalyticsSingle(emptyAnalyticsTab);
        formik.setFieldValue('platform_analytics', _platform_analytics_updated);
        setDeleteDialog(false);
    }

    const hideDeleteDialog = () => {
        setPlatformAnalyticsSingle(emptyAnalyticsTab);
        setDeleteDialog(false);
    }


    const deleteAnalyticsTabDialogFooter = (
        <React.Fragment>
            <Button type="button" label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button type="button" label="Yes" icon="pi pi-check" className="p-button-text" onClick={onDeleteAnalyticsTab} />
        </React.Fragment>
    );


    const confirmDeleteAnalytics = (platform_analytics_row) => {
        setPlatformAnalyticsSingle({ ...platform_analytics_row });
        setDeleteDialog(true);
    }


    const table_header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Analytics Tab</h5>
            <Button type="button" label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editAnalyticsTab(rowData)} />
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteAnalytics(rowData)} />
            </React.Fragment>
        );
    }

    const MyToggleButton = ({
        label,
        name,
        value,
        handleChange,
        ...props
    }) => (<div className="field col ">
        {/* <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} /> */}
        <h2 htmlFor={name} className={classNames({ 'p-error': isFormFieldValid(name) })}>{label}</h2>
        <ToggleButton id={name} name={name} checked={value} onIcon="pi pi-check" offIcon="pi pi-times" onChange={handleChange} {...props} />

    </div>);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _platformAnalyticsSingle = { ...platformAnalyticsSingle };
        _platformAnalyticsSingle[`${name}`] = val;

        setPlatformAnalyticsSingle(_platformAnalyticsSingle);
    }

    const accept = () => {
        navigate(-1);
    };

    const reject = () => {
        // alert('rejet')
        setVisible(false)
    };

    const goBack = () => {
        if (formik.dirty || didAnalyticsChange){
            setVisible(true)
        }else
            navigate(-1);
    }

    const msg = () => (
        <span>
            You might have unsaved changes.
            <br></br>
            <br></br>
            Are you sure you want to proceed?
        </span>
    )


    return (
        <div className="content-container">
            <div style={{ maxWidth: "100%", padding: "1.2vw" }}>

                <form onSubmit={formik.handleSubmit} className="formgrid grid ">
                    
                    <div className='col-12 grid'>
                    <div className='col-1'>
                        <Button id='go-back-simon' type='button' icon="pi pi-arrow-left" onClick={goBack}/>
                        <ConfirmPopup target={document.getElementById('go-back-simon')} visible={visible} onHide={() => setVisible(false)} message={msg}
                    icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
                        
                    </div>
                    <div className='col-10'>
                        <h1 className="text-center">{platformConfig.platform}</h1>
                    </div>
                    <div className='col-1'>
                        <Button type='submit' label='Submit' icon='pi pi-save'/>
                    </div>
                    </div>


                    <MyToggleButton label='Basic Validation' name="validate" handleChange={formik.handleChange} value={formik.values.validate} />
                    <MyToggleButton label='Upload to S-Pro' name="spro_upload" handleChange={formik.handleChange} value={formik.values.spro_upload} />
                    <MyToggleButton label='Map Data' name="map_data" handleChange={formik.handleChange} value={formik.values.map_data} />
                    <div className="field col ">
                        <h2 htmlFor='data_types' className={classNames({ 'p-error': isFormFieldValid('data_types') })}>Supported Data Types</h2>
                        <SelectButton id='data_types' name='data_types' optionLabel="name" value={formik.values.data_types} options={data_ypes_options} onChange={formik.handleChange} multiple></SelectButton>
                    </div>
                    <div className="field col-12 ">
                        <DataTable value={formik.values.platform_analytics} showGridlines dataKey="id" header={table_header}
                            paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll" stripedRows sortField="update_date"
                        >
                            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>
                            {dynamicColumns}

                        </DataTable>
                    </div>

                    <Dialog visible={analyticsDialog} style={{ width: '450px' }} header="Add New/Edit Analytics Tab" modal className="p-fluid" footer={analyticsDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={platformAnalyticsSingle.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !platformAnalyticsSingle.title })} />
                            {submitted && !platformAnalyticsSingle.title && <small className="p-error">Title is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="iframe_title">Iframe Title</label>
                            <InputText id="iframe_title" value={platformAnalyticsSingle.iframe_title}  onChange={(e) => onInputChange(e, 'iframe_title')} required autoFocus className={classNames({ 'p-invalid': submitted && !platformAnalyticsSingle.iframe_title })} />
                            {submitted && !platformAnalyticsSingle.iframe_title && <small className="p-error">Iframe Title is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="url">URL</label>
                            <InputTextarea id="url" value={platformAnalyticsSingle.url} onChange={(e) => onInputChange(e, 'url')} required rows={3} cols={10} className={classNames({ 'p-invalid': submitted && !platformAnalyticsSingle.url })} />
                            {submitted && !platformAnalyticsSingle.url && <small className="p-error">URL is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="groups">AWS Group</label>
                            <Dropdown id='group' value={platformAnalyticsSingle.group} options={group_options} required onChange={(e) => onInputChange(e, 'group')} placeholder="Select a Group" className={classNames({ 'p-invalid': submitted && !platformAnalyticsSingle.group })} />
                            {submitted && !platformAnalyticsSingle.group && <small className="p-error">Group is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="downloads">Downloads</label>
                            <Chips id='downloads' value={platformAnalyticsSingle.downloads} onChange={(e) => onInputChange(e, 'downloads')} tooltip="Add AWS s3 URLs for different downloads."></Chips>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Delete Analytics Tab" modal className="p-fluid" footer={deleteAnalyticsTabDialogFooter} onHide={hideDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {platformAnalyticsSingle && <span>Are you sure you want to delete <b>{platformAnalyticsSingle.title}</b>?</span>}
                        </div>
                    </Dialog>
                </form>
            </div>
        </div>
    );
}
import _ from "lodash";

let euroFormat = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'EUR',
});

const formatCurrency = (value) => {
    if (value == undefined) {
        return '-';
    }
    return `${euroFormat.format(value)}`;
}

const formatPercentage = (value) => {
    if (value == undefined) {
        return '-';
    }
    return `${(value * 100).toFixed(2)}%`;
}

const formatTerm = (value) => {
    if (value == undefined) {
        return '-';
    }
    return `${_.ceil(value * 2) / 2}m`;
}

const formatDate = (value) => {
    if (value == undefined) {
        return '-';
    }
    return `${value.substr(0, 10)}`;
}

const formatValue = (value) => {
    if (value == undefined || value == null) {
        return '-';
    }
    return `${value}`;
}

export const PLATFORM_OVERVIEW_COLUMNS = [
    {
        header: 'Platform',
        field: 'platform',
    },
    {
        header: 'SME',
        field: 'SME.loan_count',
        body: rowData => formatValue(_.get(rowData, 'SME.loan_count', undefined))
    },
    {
        header: 'CONSUMER',
        field: 'CONSUMER.loan_count',
        body: rowData => formatValue(_.get(rowData, 'CONSUMER.loan_count', undefined))
    },
    {
        header: 'Total',
        field: 'total_loan_count',
    },
    {
        header: 'Inception',
        field: 'total_origination_volume',
        body: rowData => { return formatCurrency(rowData.total_origination_volume) },
    },
    {
        header: 'Currency',
        field: 'original_currency',
    },
    {
        header: 'Default Rate SME',
        field: 'SME.default_rate_by_platform_status',
        body: rowData => formatPercentage(_.get(rowData, 'SME.default_rate_by_platform_status', undefined))
    },
    {
        header: 'Default Rate CONSUMER',
        field: 'CONSUMER.default_rate_by_platform_status',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.default_rate_by_platform_status', undefined)),
    },
    {
        header: 'Average Term SME',
        field: 'SME.average_term',
        body: rowData => formatTerm(_.get(rowData, 'SME.average_term', undefined))
    },
    {
        header: 'Average Term CONSUMER',
        field: 'CONSUMER.average_term',
        body: rowData => formatTerm(_.get(rowData, 'CONSUMER.average_term', undefined)),
    },
    {
        header: 'Average Interest Rate SME',
        field: 'SME.average_interest_rate',
        body: rowData => formatPercentage(_.get(rowData, 'SME.average_interest_rate', undefined))
    },
    {
        header: 'Average Interest Rate CONSUMER',
        field: 'CONSUMER.average_interest_rate',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.average_interest_rate', undefined)),
    },
    {
        header: 'Average Loan Size SME',
        field: 'SME.average_loan_size',
        body: rowData => formatCurrency(_.get(rowData, 'SME.average_loan_size', undefined))
    },
    {
        header: 'Average Loan Size CONSUMER',
        field: 'CONSUMER.average_loan_size',
        body: rowData => formatCurrency(_.get(rowData, 'CONSUMER.average_loan_size', undefined)),
    },
    {
        header: 'Last Updated SME',
        field: 'SME.last_updated',
        body: rowData => formatValue(_.get(rowData, 'SME.last_updated', undefined))
    },
    {
        header: 'Last Updated CONSUMER',
        field: 'CONSUMER.last_updated',
        body: rowData => formatValue(_.get(rowData, 'CONSUMER.last_updated', undefined))
    },
    {
        header: 'Recovery Rate SME',
        field: 'SME.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'SME.recovery_rate', undefined))
    },
    {
        header: 'Recovery Rate CONSUMER',
        field: 'CONSUMER.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.recovery_rate', undefined))
    },
]

export const PLATFORM_DETAIL_HEADER_COLUMNS = [
    {
        header: 'Total Loan Count',
        field: 'total_loan_count',
    },
    {
        header: 'Origination Volume Since 3 Months*',
        field: 'total_origination_volume_since_3_months',
        body: rowData => { return formatCurrency(rowData.total_origination_volume_since_3_months) },
    },
    {
        header: 'Origination Volume Since 6 Months*',
        field: 'total_origination_volume_since_6_months',
        body: rowData => { return formatCurrency(rowData.total_origination_volume_since_6_months) },
    },
    {
        header: 'Origination Volume Since 12 Months*',
        field: 'total_origination_volume_since_12_months',
        body: rowData => { return formatCurrency(rowData.total_origination_volume_since_12_months) },
    },
    {
        header: 'Origination Volume Since Inception*',
        field: 'total_origination_volume',
        body: rowData => { return formatCurrency(rowData.total_origination_volume) },
    },
    {
        header: 'Original Currency',
        field: 'original_currency',
    },
]

export const PLATFORM_DETAIL_SME_COLUMNS = [
    {
        header: 'Loan Count',
        field: 'SME.loan_count',
        body: rowData => formatValue(_.get(rowData, 'SME.loan_count', undefined))
    },
    {
        header: 'Average Term',
        field: 'SME.average_term',
        body: rowData => formatTerm(_.get(rowData, 'SME.average_term', undefined))
    },
    {
        header: 'Average Interest Rate',
        field: 'SME.average_interest_rate',
        body: rowData => formatPercentage(_.get(rowData, 'SME.average_interest_rate', undefined))
    },
    {
        header: 'Average Loan Size*',
        field: 'SME.average_loan_size',
        body: rowData => formatCurrency(_.get(rowData, 'SME.average_loan_size', undefined))
    },
    {
        header: 'Last Updated',
        field: 'SME.last_updated',
        body: rowData => formatValue(_.get(rowData, 'SME.last_updated', undefined))
    },
    {
        header: 'Default Rate (Platform)',
        field: 'SME.default_rate_by_platform_status',
        body: rowData => formatPercentage(_.get(rowData, 'SME.default_rate_by_platform_status', undefined))
    },
    {
        header: 'Default Rate (Exaloan)',
        field: 'SME.default_rate_by_exaloan_status',
        body: rowData => formatPercentage(_.get(rowData, 'SME.default_rate_by_exaloan_status', undefined))
    },
    // {
    //     header: 'Loss Rate (Platform)',
    //     field: 'SME.loss_rate_by_platform_status',
    //     body: rowData => formatPercentage(_.get(rowData, 'SME.loss_rate_by_platform_status', undefined))
    // },
    // {
    //     header: 'Loss Rate (Exaloan)',
    //     field: 'SME.loss_rate_by_exaloan_status',
    //     body: rowData => formatPercentage(_.get(rowData, 'SME.loss_rate_by_exaloan_status', undefined))
    // },
    {
        header: 'Recovery Rate (Platform)',
        field: 'SME.platform_calculations.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'SME.platform_calculations.recovery_rate', undefined))
    },
    {
        header: 'Recovery Rate (Exaloan)',
        field: 'SME.exaloan_calculations.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'SME.exaloan_calculations.recovery_rate', undefined))
    },
]

export const PLATFORM_DETAIL_CONSUMER_COLUMNS = [
    {
        header: 'Loan Count',
        field: 'CONSUMER.loan_count',
        body: rowData => formatValue(_.get(rowData, 'CONSUMER.loan_count', undefined))
    },
    {
        header: 'Average Term',
        field: 'CONSUMER.average_term',
        body: rowData => formatTerm(_.get(rowData, 'CONSUMER.average_term', undefined)),
    },
    {
        header: 'Average Interest Rate',
        field: 'CONSUMER.average_interest_rate',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.average_interest_rate', undefined)),
    },
    {
        header: 'Average Loan Size*',
        field: 'CONSUMER.average_loan_size',
        body: rowData => formatCurrency(_.get(rowData, 'CONSUMER.average_loan_size', undefined)),
    },
    {
        header: 'Last Updated',
        field: 'CONSUMER.last_updated',
        body: rowData => formatValue(_.get(rowData, 'CONSUMER.last_updated', undefined))
    },
    {
        header: 'Default Rate (Platform)',
        field: 'CONSUMER.default_rate_by_platform_status',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.default_rate_by_platform_status', undefined)),
    },
    {
        header: 'Default Rate (Exaloan)',
        field: 'CONSUMER.default_rate_by_exaloan_status',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.default_rate_by_exaloan_status', undefined)),
    },
    // {
    //     header: 'Loss Rate (Platform)',
    //     field: 'CONSUMER.loss_rate_by_platform_status',
    //     body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.loss_rate_by_platform_status', undefined)),
    // },
    // {
    //     header: 'Loss Rate (Exaloan)',
    //     field: 'CONSUMER.loss_rate_by_exaloan_status',
    //     body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.loss_rate_by_exaloan_status', undefined)),
    // },
    {
        header: 'Recovery Rate (Platform)',
        field: 'CONSUMER.platform_calculations.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.platform_calculations.recovery_rate', undefined))
    },
    {
        header: 'Recovery Rate (Exaloan)',
        field: 'CONSUMER.exaloan_calculations.recovery_rate',
        body: rowData => formatPercentage(_.get(rowData, 'CONSUMER.exaloan_calculations.recovery_rate', undefined))
    },
]
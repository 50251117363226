
import React, { useContext, useRef } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';

import './stepper.css';
import { FormContext } from '../ClimateToolboxGeneration';

export const Stepper = () => {
    const { activeIndex, setActiveIndex } = useContext(FormContext);
    const toast = useRef(null);
    const items = [
        {
            label: 'Adresse',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Firmenadresse', detail: event.item.label });
            }
        },
        {
            label: 'Information',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Grundlegende Firmeninformationen', detail: event.item.label });
            }
        },
        {
            label: 'Finanzen',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Finanzkennzahlen', detail: event.item.label });
            }
        },
        {
            label: 'Energie',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Energie', detail: event.item.label });
            }
        },
        {
            label: 'ESG',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Environmental, Social, Governance', detail: event.item.label });
            }
        }
    ];

    return (
        <div className="stepper">
            <Toast ref={toast}></Toast>

            <div className="card">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
            </div>
        </div>
    );
}
                 
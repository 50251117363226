import React from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { AuthContext } from "../../context/AuthContext";


export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage("token", null);
    const [user, setUser] = useLocalStorage("user", null);
    const [groups, setGroups] = useLocalStorage("groups", null);
    const [platform,setPlatform]= useLocalStorage("platform", null);
    const [platform_config,setPlatformConfig]= useLocalStorage("platform_config", null);
    const navigate = useNavigate();
  
    // call this function when you want to authenticate the user
    const login = async (token,user,groups,platform,platform_config) => {
      console.log("Auth Provider::Login");
      setToken(token);
      console.log(token);
      console.log(user);
      console.log(platform);
      console.log(platform_config)
      setUser(user);
      setGroups(groups);
      setPlatform(platform);
      setPlatformConfig(platform_config);
      navigate("/");
    };

    const updatePlatformConfig = async (platform_config) => {
      console.log('Updating Platform Config');
      setPlatformConfig(platform_config)
    }
  
    // call this function to sign out logged in user
    const logout = () => {
      setToken(null);
      setUser(null);
      setGroups(null);
      setPlatform(null);
      setPlatformConfig(null);
      console.log("Auth Provider::Logout");
      navigate("/", { replace: true });
    };
  
    const value = useMemo(
      () => ({
        token,
        login,
        logout,
        user,
        groups,
        platform,
        platform_config,
        updatePlatformConfig
      }),
      [token,user,groups,platform,platform_config]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  };
  

  
import React, { useEffect } from 'react';
import { withCookies } from "react-cookie";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../hooks/useAuth';
import "./logout.css";


function LogOut(props){
  const { logout } = useAuth();

   async function log_out() {
    // let history = useHistory();
    console.log(props)
    // props.logout();
    const { cookies } = props;
    await Auth.signOut().then(e => {    
      logout();
    }).catch(err => { console.log(err) })
  };

  // componentDidMount() {
  //   setTimeout(() => this.log_out(), 1000)
  // };

  useEffect(() => {
    setTimeout(() => log_out(), 1000)
  });


  
    return (
      <div className='container2'>
        <p className='logOut'>Thank you for visiting ExaPort.</p>
        <p className='logOut'>We wish to see you again soon!</p>
      </div>
    );
};

export default withCookies(LogOut);
import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { isEmpty, isNull } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { FileUpload as FileUploader } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';

import ClimateToolboxSlidein from './ClimateToolboxSlideIn';
import ClimateToolboxGeneration from './ClimateToolboxGeneration';
import axiosWithAuth from "../../helpers/AxiosHelper";
import "./climate_toolbox.css"

let esg_data_types = [
    { id: 1, name: 'Aktivitätsbasierte', value: 'ACTIVITY' },
    { id: 2, name: 'Sektorbasierte', value: 'SECTOR' },
]

const ClimateToolbox = (props) => {

    const [esg_data_type, setESGDataType] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const fileValidation = (file) => {
        console.debug(file)
        console.log(file)
        if (file.size > 200000000)
            return false;
        return true;
    }

    const onFileUpload = (event) => {
        // event.preventDefault();
        setError('');
        // setState({ error: '' });
        let file_to_upload = event.files[0]
        if (!fileValidation(file_to_upload)) {
            setError('Die Dateigröße ist zu groß. Mehr als 200 MB können nicht hochgeladen werden.');
            return;
        }
        console.log()
        if (isNull(esg_data_type) || isEmpty(esg_data_type)) {
            setError('Dateityp darf nicht null sein.')
            return;
        }
        const formData = new FormData();
        formData.append(
            "uploadedFile",
            file_to_upload,
            file_to_upload.name
        );
        formData.append('data_type', esg_data_type)
        props.setLoading(true);
        let v = axiosWithAuth.post('/files', formData).then(() => {
            let msg = `Datei ${file_to_upload.name} wurde erfolgreich hochgeladen`
            setSuccess(msg);
        }).catch((err) => {
            console.log(err.toJSON());
            if (err.message) {
                console.log(err.message)
                setError(err.message);
            }
        }).finally(() => {
            event.options.clear();
            setESGDataType('')
            props.setLoading(false);
        });
        console.log(v);
    };



  const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Datei hierher ziehen und ablegen(drag-and-drop)</span>
            <small>Maximale Dateigröße: 200MB.</small>
            <small>Wir akzeptieren .xlsx, .xls, .csv, .json Dateien</small>
        </div>
    )
}
    return (
        <div className="content-container">
            <div style={{ maxWidth: "100%", maxHeight: "100%", minHeight: "50%" }}>
                <TabView>
                    {/* <TabPanel header="Hochladen">
                        <ClimateToolboxSlidein />
                        <div className="upload">
                            {error && <div name='error' className="errorMsg ">{error}</div>}
                            {success && <div name='success' className="successMsg ">{success}</div>}
                            <div className="p-fluid grid" style={{ 'maxWidth': '600px' }}>
                                <div className="field col-12 ">
                                    <FileUploader name='loanbook' accept=".xlsx, .xls, .csv, .json" maxFileSize="200000000" chooseLabel='Datei auswählen'
                                    uploadLabel='Hochladen' cancelLabel='Abbrechen' emptyTemplate={emptyTemplate} customUpload uploadHandler={onFileUpload} />
                                </div>
                                <div className="field col-12 ">
                                    <span className="p-float-label upload-form-items">
                                        <Dropdown inputId="dataType" value={esg_data_type} optionLabel="name" optionValue='value' onChange={(e) => setESGDataType(e.value)} options={esg_data_types} />
                                        <label htmlFor="dataType">Dateityp</label>
                                    </span>
                                    <small id="dataType-help" >Wählen Sie die Art der in der Datei vorhandenen Darlehen aus.</small>
                                </div>
                            </div>
                        </div>
                    </TabPanel> */}
                    <TabPanel header="Firmendaten Hinzufügen">
                        <ClimateToolboxGeneration/>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )

};

export default ClimateToolbox;


import React from 'react';
import { Chip } from 'primereact/chip';

function group_color(group) {
    let color = "grey"
    switch (group) {
        case "Admin":
            color = "#FF9280"
            break;
        case "User":
            color = "#00C0C0"
            break;
        case "Analytics":
            color = "#FFDA9D"
            break;
        case "ECS":
            color = "#8AE28A"
            break;
        case "Monitoring":
            color = "#D397F8"
            break;
        default:
            break;
    }
    return color
}

function status_color(status) {
    let color = "grey"
    switch (status) {
        case "CONFIRMED":
            color = "#077186"
            break;
        case "UNCONFIRMED":
            color = "#00C0C0"
            break;
        case "ARCHIVED":
            color = "#FFDA9D"
            break;
        case "FORCE_CHANGE_PASSWORD":
            color = "orange"
            break;
        case "Monitoring":
            color = "#D397F8"
            break;
        default:
            break;
    }
    return color
}


export const USER_COLUMNS = [
    {
        header: 'Enabled',
        field: 'enabled',
        sortable:true,
        body: (rowdata) => {
            if (rowdata.enabled){
               return  <i className="pi pi-check-circle" style={{ color: 'green', fontSize:'1.4rem'}}></i>
            }else{
                return <i className="pi pi-times-circle" style={{ color: 'red', fontSize:'1.4rem'}}></i>
            }
        },
        align:'center',
        style:{width:'5%'}
    },
    
    {
        header: 'Platform',
        field: 'platform',
        sortable: true
    },
    {
        header: 'Email',
        field: 'email',
        sortable: true
    },
    {
        header: 'Groups',
        field: 'groups',
        body: (rowdata) => {
            return rowdata.groups.map((col, i) => {
                return <Chip key={i} id='group' label={col} style={{ background: group_color(col), color: 'black', marginRight: '5px', borderRadius: '100px' }} />
            });
        }
    },
    
    {
        header: 'Status',
        field: 'status',
        sortable:true,
        body: (rowdata) => {
            return <Chip  id='status' label={rowdata.status} style={{ background: status_color(rowdata.status), color: 'white', marginRight: '5px', borderRadius: '100px' }} />
        }
    }
]


import React, { useMemo, useState, useEffect, useRef, forwardRef } from "react";
import Verlag from '../../assets/fonts/Verlag-Book.otf';
import axiosWithAuth from "../../helpers/AxiosHelper";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {Toast} from 'primereact/toast';
import "../../../node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"
import 'primeicons/primeicons.css';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import "./file_tracking.css";
import { useAuth } from "../../hooks/useAuth";
import { FILE_UPLOAD_COLUMNS } from "./trackColumns";
import { Calendar } from 'primereact/calendar';

const verlag = {
  fontFamily: 'Verlag',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url(${Verlag}) format('OpenType')`,
};

const style = {
  testTransform: "uppercase",
  fontsize: "30px",
  color: "black",
};

const TrackFile = () => {
  const [fileData, setFileData] = useState([]);
  const [loading, isLoading] = useState(false)
  const { platform } = useAuth();
  const [columns, setColumns] = useState(FILE_UPLOAD_COLUMNS);
  const [showModal, setShowModal] = useState(false);
  const toast = useRef(null);
  const [myMap, setMyMap] = useState(new Map());
  const updateMap = (k, v) => {
    setMyMap(new Map(myMap.set(k, v)));
  }
  const [expandedRows, setExpandedRows] = useState(null);
  useEffect(() => {
    isLoading(true)
    const result = axiosWithAuth.get("/files", { params: { Platform: platform } })
    result.then(data => {
      if (platform === 'Exaloan') {
        setColumns(get_admin_columns())
      }
      console.log('Inside this one 2')
      let fetched_data = data.data
      isLoading(false)
      setFileData([...fetched_data || []].map(d => {
        d.upload_date = new Date(d.upload_date);
        return d;
      }));
      // setFileData(data.data)
    }).catch(err => {
      console.error(err)
    });
    const interval = setInterval(() => {

      axiosWithAuth.get("/files", { params: { Platform: platform } }).then(data => {
        if (platform === 'Exaloan') {
          setColumns(get_admin_columns())

        }
        console.log('Inside this one 2')
        let fetched_data = data.data
        setFileData([...fetched_data || []].map(d => {
          d.upload_date = new Date(d.upload_date);
          return d;
        }));
        // setFileData(data.data)
      }).catch(err => {
        console.error(err)
      });
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  function get_admin_columns(){
    let admin_columns = [
      {
      header: 'Platform',
      field: 'platform',
      sortable: true,
      filter: true,
      cellstyle: { color: "black" },
      }, ...FILE_UPLOAD_COLUMNS,
      {
      header: 'ReqID',
      field: 'req_id',
      sortable:true,
      filter:true
      },
      {
        header: 'Retry',
        body: (rowData) => {
          return <React.Fragment> 
            <Button type="button" icon="pi pi-refresh" loading={rowData.loading} onClick={(event) => retryMapping(event,rowData)}></Button>
            </React.Fragment>
          }
        },

    ]
    return admin_columns
  }

  function retryMapping(event,rowData){
    console.log(event)
    console.log("Retrying Mapping")
    isLoading(true)
    rowData.loading = true
      const result = axiosWithAuth.post(`/files/retry/${rowData.req_id}`)
      result.then(data => {
        console.log(data)
        toast.current.show({severity:'success', summary: 'Retry Started', detail:"Retry has started in the background", life: 3000});

      }).catch(err => {
        toast.current.show({severity:'error', summary: 'Retry Failed', detail:String(err), life: 2000});
        console.error(err)
        // alert(err)
      }).finally(() => {
        rowData.loading=false
        isLoading(false)
      });
  }

  function showToast(msg){
  }

  function refreshData() {
    isLoading(true)
    const result = axiosWithAuth.get("/files", { params: { Platform: platform } })
    result.then(data => {
      if (platform === 'Exaloan') {
        setColumns(get_admin_columns())
      }
      console.log('Inside this one 2')
      let fetched_data = data.data
      setFileData([...fetched_data || []].map(d => {
        d.upload_date = new Date(d.upload_date);
        return d;
    }));
      // setFileData(data.data)
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      isLoading(false)
    });
  }
    
  let expandedRowStyle = {
    fontSize: 15,
    textAlign: 'left',
    maxWidth: '100%',
    fontWeight: 700
  }

  const rowExpansionTemplate = (data) => {
    // console.log(rowData.rowData);
    let rowData = data;
    if (rowData.status==='Error' || rowData.status==='Rejected')
      return (
        <div
          style={expandedRowStyle}
        >
          {rowData.error_message?<p style={{ width: '100%', wordWrap: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{rowData.error_message}</p>:'Server Error. Please Contact Admin'}

          {rowData.error_details && rowData.error_details.msg && <div >
            <p>For file <b style={{ color: "red" }}>{rowData.file_name}</b>, {rowData.error_details.msg}</p>
            {rowData.error_details.columns_affected &&
              <div style={{ "maxHeight": "200px", "overflowY": "auto" }}>
                {rowData.error_details.columns_affected.map((obj, i) => {
                  return <p style={{ color: "red" }}>{i + 1}. {obj}</p>
                    ;
                })}
              </div>
            }
          </div>}
          {platform==='Exaloan' && rowData.stack_trace && 
              <p>{rowData.stack_trace.flatMap((val) => [val,<br/>])}</p>
            }

        </div>
      )
    else
      return (<div
        style={expandedRowStyle}>
        <p style={{ width: '100%', wordWrap: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBlockEnd: "0.5em" }}>
          {rowData.status == "Approved" ? "File has been Approved. And will be uploaded shortly." : rowData.status == "Uploaded" ? "File has been uploaded successfully." : "File has been received and will be approved and uploaded shortly."}
        </p>
        {
          rowData.message &&
          <p style={{ width: '100%', color: "red", wordWrap: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBlockStart: 0 }}>
            Duplicates Detected: {rowData.message}
          </p>
        }
      </div>)
  }

  const allowExpansion = (rowData) => {
    return true;
  };

  const filters = {
    'name': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  }

  const dynamicColumns = columns.map((col, i) => {
    return <Column filter={col.filter} key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} filterElement={col.filterElement}
      dataType={col.dataType} filterField={col.filterField} style={col.style} />;
  });

  function GetTitle(props) {
    const { title } = props;
    return <span className='text_title' style={style}>{title}</span>;
  }

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <GetTitle title="File Upload History" />
        <div>
        <Button icon="pi pi-refresh" onClick={refreshData} />
        <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={initFilters1} />
        </div>
      </div>
    )
  }

  let title = <GetTitle title="File Upload History" />;
  const [filters2, setFilters2] = useState({
    'platform': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'file_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'upload_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    'data_type': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    'num_of_rows': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'req_id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  });

  const initFilters1 = () => {
    setFilters2({
      'platform': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'file_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'upload_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      'data_type': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      'num_of_rows': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
  }

  return (
    <div><Toast ref={toast} position="top-center"/>
    <div className="content-container">
      
      <DataTable value={fileData} header={renderHeader1} showGridlines loading={loading} rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} dataKey="_key"
        paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll" stripedRows sortField="upload_date"
        filterDisplay='menu' filters={filters2}
      >
        <Column expander={allowExpansion} style={{ width: '3em' }} />
        {dynamicColumns}
      </DataTable>
      </div>
    </div>
  );
}

export default TrackFile;

import React, { useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import axiosWithAuth from '../../../helpers/AxiosHelper';
import { Users } from './Users';


export const PlatformUsers = (props) => {

    const [platformUsers,setPlatformUsers ] = useState(null)

    const { platformName } = useParams()

    const setLoadingInProps = props.setLoading

    useEffect(() => {
        setLoadingInProps(true)
        axiosWithAuth.get(`/platforms/${platformName}/users`).then((response) => {
            let data = response.data;
            console.log(data);
            setPlatformUsers(data);
            setLoadingInProps(false)
        }).catch((err) => {
            alert(err);
            setLoadingInProps(false)
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    return (
        <div className="content-container">
            <div style={{ maxWidth: "100%", padding: "1.2vw" }}>
                {platformUsers && <Users users={platformUsers}/>}
            </div>
        </div>
    );
}
import React, { useContext } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { FormContext } from '../ClimateToolboxGeneration';

export default function Energy() {
    const { formik } = useContext(FormContext);
    const isFormFieldValid = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return !!(formik.touched[name] && formik.errors[firstLevel][secondLevel]);
    }
    const getFormErrorMessage = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[firstLevel][secondLevel]}</small>;
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Energie</h4>
                <form>
                    <div className="p-fluid">
                        <div className="field col-12">
                            <label htmlFor="energy.energyConsumption" className={classNames({ 'p-error': isFormFieldValid('energy.energyConsumption') })}>Gesamter Energieverbrauch</label>
                            <InputNumber inputId="energy.energyConsumption" name="energy.energyConsumption" value={formik.values.energy.energyConsumption} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('energy.energyConsumption') })} mode="decimal" locale="de-DE" suffix=" kW/h" tooltip="Gesamter Energieverbrauch in kw/h" tooltipOptions={{mouseTrack: true}} min={0}/>
                            {getFormErrorMessage('energy.energyConsumption')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="energy.nonRenewableEnergy" className={classNames({ 'p-error': isFormFieldValid('energy.nonRenewableEnergy') })}>Anteil des Verbrauchs an nicht erneuerbarer Energie</label>
                            <InputNumber inputId="energy.nonRenewableEnergy" name="energy.nonRenewableEnergy" value={formik.values.energy.nonRenewableEnergy} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('energy.nonRenewableEnergy') })} mode="decimal" locale="de-DE" suffix=" %" tooltip="Prozentualer Anteil des Energieverbrauchs, der aus nicht erneuerbaren Energien stammt" tooltipOptions={{mouseTrack: true}} min={0} max={100}/>
                            {getFormErrorMessage('energy.nonRenewableEnergy')}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
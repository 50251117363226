import React from "react";

import Logo from "../../assets/images/exaloan.png";
import "./navigation_bar.css";

const NavBar = () => {
  return (
    <div>
      <img alt="Logo" className="logo" src={Logo} />
    </div>
  );
};

export default NavBar;
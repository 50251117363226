import React, { createContext, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';

import "./climate_toolbox.css";
import { Stepper } from './stepform/Stepper';
import Step from './stepform/Step';
import { formStructure } from './stepform/FormStructure';
import { Toast } from 'primereact/toast';
import { useAuth } from "../../hooks/useAuth";
export const FormContext = createContext();

const ClimateToolboxGeneration = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [formValues, setFormData] = useState(formStructure.formValues);
    const [esgReportName, setESGReportName] = useState('');
    const [reqID, setReqID] = useState('');
    const [formValidations] = useState(formStructure.formValidations)
    const { platform } = useAuth();
    const toast = useRef(null);

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: formValidations,
        enableReinitialize: true
        // onSubmit: (data) => {
        //     if (formReference.current) {
        //         setFormData(data);
        //         alert(JSON.stringify(data, null, 2));
        //         toast.current.show({ severity: 'success', summary: 'Successfully Submitted', detail: formik.values });
        //         // formReference.current.handleSubmit()
        //     }
        // }
    });

    const prevStep = () => {
        setActiveIndex(activeIndex - 1);
    }

    const nextStep = () => {
        formik.validateForm();
        setActiveIndex(activeIndex + 1);
    }

    const showComplete = event => {
        toast.current.show({ severity: 'success', summary: 'Erfolgreich eingereicht für Firmen:', detail: formik.values.companyName });
        event.currentTarget.disabled = true;
    }

    const addNew = () => {
        toast.current.show({ severity: 'info', summary: 'Eine neue Form anstreben' });
        formik.resetForm();
        setActiveIndex(0);
        setESGReportName('');
    }

    return (
        <div className="esg-form">
            <Toast ref={toast} />
            <FormContext.Provider value={{ activeIndex, setActiveIndex, formik, toast, esgReportName, setESGReportName, reqID, setReqID, platform }}>
                <div className="flex justify-content-center">
                    <Stepper />
                </div>
                <form>
                    <div className="flex justify-content-center">
                        <Step />
                    </div>
                    <div className="flex justify-content-end pr-8">
                        <Button label="Zurück" className="p-button-outlined mt-2" onClick={prevStep} disabled={activeIndex === 0} type="button" />
                        <Button label="Nächste" className="mt-2" onClick={nextStep} disabled={activeIndex === 5} visible={activeIndex !== 5 && activeIndex !== 6} type="button" />
                        {/* <Button label="Submit" className="mt-2" visible={activeIndex === 5} type="submit" /> */}
                        <Button label="Einreichen" className="mt-2" visible={activeIndex === 5} type="button" onClick={showComplete} />
                        <Button label="Neue Firma" className="mt-2" visible={activeIndex === 5} type="button" onClick={addNew} />
                        <Button label="Vorschau" className="mt-2" visible={activeIndex === 5} type="button" onClick={nextStep} />
                    </div>
                </form>
            </FormContext.Provider>
        </div>
    );
}

export default ClimateToolboxGeneration;
import React, { useState } from "react";
import { withCookies } from "react-cookie";
import { Button } from 'primereact/button';
import { Auth } from "aws-amplify";
import { isNil } from "lodash";
import { useNavigate } from "react-router-dom";
import { LoginType } from "./LoginType";
// https://adebola-niran.medium.com/react-loading-screen-while-a-user-is-logging-in-3-steps-bb537031d1d4
import { conv_groups_array_for_state } from '../../helpers/AccessHelper';
import axiosWithAuth from '../../helpers/AxiosHelper';
import Exaport from "../../assets/images/exaport.png";
import "./login.css";
import { useAuth } from "../../hooks/useAuth";

function LogIn(props) {

  const { token, login, logout } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user_aws, setUserAWS] = useState(null);
  const [login_type, setLoginType] = useState(LoginType.LOGIN);
  const [code, setCode] = useState('');
  const [code_button_text, setCodeButtonText] = useState('Send Code');
  const [username_error, setUsernameError] = useState('');
  const [password_error, setPasswordError] = useState('');
  const [confirm_password_error, setConfirmPasswordError] = useState('');

  function handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    switch (nam) {
      case 'username':
        setUsername(val)
        break;
      case 'password':
        setPassword(val)
        break;
      case 'confirm_password':
        if (login_type === LoginType.FORCE_CHANGE_PASSWORD || login_type === LoginType.FORGOT_PASSWORD) {
          if (password !== val) {
            setError('Passwords do not match.')
          } else {
            setError('')
          }
        }
        setConfirmPassword(val)
        break;
      case 'error':
        setError(val)
        break;
      case 'code':
        setCode(val)
        break;
      default:
        break;
    }
  };

  async function login_func(e) {
    e.preventDefault();
    setSuccess('');
    if (validateForm()) {
      console.log('Form Validated')
      props.setLoading(true);
      let data = {
        username: username,
        password: password
      }
      try {
        await Auth.signIn(data.username, data.password)
          .then(user => {
            console.log('User:');
            console.log(user);
            setUserAWS(user)
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              change_login_type(LoginType.FORCE_CHANGE_PASSWORD)
              return;
            }
            console.log(user['attributes'])

            if (!user['attributes']['custom:Platform']) {
              setError('Platform not set. Kindly ask Admin to set Platform.')
              Auth.signOut().then(val => {
                props.setLoading(false);
                logout();
              });
              console.log('Checked for Platform');

              return;
            }
            if (!user['signInUserSession']['idToken']['payload']['cognito:groups']) {
              setError('No groups set. Kindly ask Admin to set Group.')
              Auth.signOut().then(val => {
                props.setLoading(false);
                logout();
              });
              console.log('Checked for Groups');

              return;
            }
            console.log('After Platform');

            const get_config = axiosWithAuth.get("/platforms/config", { params: { platform: user['attributes']['custom:Platform'] } })

            get_config.then((data) => {
              console.log(data.data[0])
              props.setLoading(false);
              console.log(login);
              props.setGroups(conv_groups_array_for_state(user['signInUserSession']['idToken']['payload']['cognito:groups']))
              login(user['signInUserSession']['idToken']['jwtToken'],
                user['attributes']['email'],
                conv_groups_array_for_state(user['signInUserSession']['idToken']['payload']['cognito:groups']),
                user['attributes']['custom:Platform'],
                data.data[0]);
            }).catch(err => { console.log(err); logout(); props.setLoading(false); setError(err.message); })
          })
          .catch(err => { console.log(err); props.setLoading(false); setError(err.message); })
      } catch (error) {
        props.setLoading(false);
        setError(error.message)
      }
    }
  };

  async function changePassword(e) {
    e.preventDefault();
    props.setLoading(true);
    setError('');
    setSuccess('');
    console.log(username)
    if (!password) {
      setError("*Please enter your password.");
      props.setLoading(false);
      return;
    }
    if (password !== confirm_password) {
      setError('Passwords do not match.');
      props.setLoading(false);
      return;
    }
    const { cookies } = props;
    try {
      await Auth.completeNewPassword(user_aws, password, { email: username })
        .then(user => {
          console.log('In completeNewPassword');
          change_login_type(LoginType.LOGIN);
          setUsername(username);
          setSuccess('Password Changed')
          props.setLoading(false);
        })
        .catch(err => { console.log(err); props.setLoading(false); setError(err.message); })
    } catch (error) {
      setError(error.message)
      props.setLoading(false);
    }
  }

  function handleSubmit(input) {
    input.preventDefault();

  };

  function validateForm() {
    let formIsValid = true;

    if (!username) {
      formIsValid = false;
      setError("*Please enter your Email.");
    }
    else if (!isNil(username)) {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(username)) {
        formIsValid = false;
        setError("*Please enter valid email Address.");
      }
    }
    else if (!password) {
      formIsValid = false;
      setError("*Please enter your password.");
    }
    if (formIsValid)
      setError('');
    return formIsValid;
  }

  function change_login_type(login_type_to_change_to) {
    props.setLoading(true);
    switch (login_type_to_change_to) {
      case LoginType.LOGIN:
        setUsername('');
        setPassword('');
        setError('');
        break;
      case LoginType.FORGOT_PASSWORD:
        setPassword('');
        setError('');
        break;
      case LoginType.FORCE_CHANGE_PASSWORD:
        setPassword('');
        break;
    }
    setLoginType(login_type_to_change_to)
    props.setLoading(false);
  }

  async function send_forgot_password_code(e) {
    if (!username) {
      setError("*Please enter your Email.");
      return;
    }

    Auth.forgotPassword(username)
      .then(data => console.log(data))
      .catch(err => { console.log(err); setError(err.message); });

  };

  async function forgot_password(e) {
    e.preventDefault();
    props.setLoading(true);
    setError('');
    console.log(username)
    let error_msg = ""
    let formIsValid = true;
    if (!username) {
      formIsValid = false;
      error_msg = "*Please enter your username.";
      return;
    }
    else if (!password) {
      formIsValid = false;
      error_msg = "*Please enter your password.";
    }
    else if (password != confirm_password) {
      formIsValid = false;
      error_msg = 'Passwords do not match.';
    }
    else if (!code) {
      formIsValid = false;
      error_msg = "*Please enter the code received on your email.";
    }
    if (!formIsValid) {
      setError(error_msg);
      props.setLoading(false);
      return;
    }

    await Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        console.log(data);
        change_login_type(LoginType.LOGIN);
        return;
      })
      .catch(err => { console.log(err); setError(err.message); });
  };


  if (login_type == LoginType.LOGIN)
    return (
      <div>
        <div id="container" className='login'>
          <img alt="" className="logo" src={Exaport} />
          <p className="title">Log in to your account</p>
          <div name='error' className="errorMsg">{error}</div>
          <div name='success' className="successMsg">{success}</div>
          <form method="post" name="userRegistrationForm" onSubmit={login_func} className="space">
            <input name='username' className='input ${formIsValid?"":"errorInput"}' type="text" placeholder="Username" value={username} onChange={handleChange} />
            <div name='username_error' className="errorMsg">{username_error}</div>
            <input name='password' className="input" type="password" placeholder="Password" value={password} onChange={handleChange} />
            <div name='password_error' className="errorMsg">{password_error}</div>

            <Button label="Login" type="submit" />
          </form>
          <Button label="Forgot Password" onClick={() => {
            change_login_type(LoginType.FORGOT_PASSWORD)
          }} />
        </div>
      </div>
    );
  if (login_type == LoginType.FORCE_CHANGE_PASSWORD)
    return (
      <div>
        <div id="container" className='login'>
          <img alt="" className="logo" src={Exaport} />
          <p className="title">Please change Password.</p>
          <div name='error' className="errorMsg">{error}</div>
          <form method="post" name="userRegistrationForm" onSubmit={changePassword} className="space">
            <input name='username' className="input" type="text" placeholder="Username" value={username} onChange={handleChange} readOnly={true} />
            <div name='username_error' className="errorMsg">{username_error}</div>
            <input name='password' className="input" type="password" placeholder="New Password" value={password} onChange={handleChange} />
            <div name='password_error' className="errorMsg">{password_error}</div>
            <input name='confirm_password' className="input" type="password" placeholder="Confirm Password" value={confirm_password} onChange={handleChange} />
            <div name='confirm_password_error' className="errorMsg">{confirm_password_error}</div>
            <Button label="Cancel" onClick={() => {
              change_login_type(LoginType.LOGIN)
            }} />

            <Button label="Confirm" type="submit" />
          </form>
        </div>
      </div>
    );
  if (login_type == LoginType.FORGOT_PASSWORD)
    return (
      <div>
        <div id="container" className='login'>
          <img alt="" className="logo" src={Exaport} />
          <p className="title">Please Enter New Password And Code</p>
          <div name='error' className="errorMsg">{error}</div>
          <Button variant="text" onClick={() => { send_forgot_password_code() }}>{code_button_text}</Button>
          <p>To send code please click on the button above</p>
          <form method="post" name="userRegistrationForm" onSubmit={forgot_password} className="space">
            <input name='username' className="input" type="text" placeholder="Username" value={username} onChange={handleChange} />
            <div name='username_error' className="errorMsg">{username_error}</div>
            <input name='password' className="input" type="password" placeholder="New Password" value={password} onChange={handleChange} />
            <div name='password_error' className="errorMsg">{password_error}</div>
            <input name='confirm_password' className="input" type="password" placeholder="Confirm Password" value={confirm_password} onChange={handleChange} />
            <div name='confirm_password_error' className="errorMsg">{confirm_password_error}</div>
            <input name='code' className="input" type="number" placeholder="Enter Code" value={code} onChange={handleChange} />
            <Button label="Cancel" onClick={() => {
              change_login_type(LoginType.LOGIN)
            }} />

            <Button label="Confirm" type="submit" />
          </form>
        </div>
      </div>
    );
}

export default withCookies(LogIn);

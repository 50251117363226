import React from "react";

import "./loader.css"

export const Spinner = () => {

  return (
    <div className="block">
      <span className="ouro ouro3">
        <span className="left"><span className="anim"></span></span>
        <span className="right"><span className="anim"></span></span>
      </span>
    </div>
  );
}

export default Spinner;

// spinner design I've alted and am using
// https://jsfiddle.net/sj091mtp/16/ 
// types of spinner available
// https://codepen.io/resourceguru/pen/szKhH
// tutorial on loading page:
// https://adebola-niran.medium.com/react-loading-screen-while-a-user-is-logging-in-3-steps-bb537031d1d4
// https://stackoverflow.com/questions/60751061/how-to-show-loader-in-button-only-to-current-mapped-value
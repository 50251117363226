import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { useAuth } from '../../hooks/useAuth';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from "primereact/dropdown";
import { check_admin_access } from '../../helpers/AccessHelper'
import axiosWithAuth from "../../helpers/AxiosHelper";
import { PLATFORM_CONFIGURATION_COLUMNS, MONITORING_COLUMNS } from './monitoringColumns';
import "./monitoring.css";
import "../../../node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
// const style = {
//   testTransform: "uppercase",
//   fontsize: "30px",
//   color: "black",
// };

function Monitoring(props) {

  const { groups } = useAuth();
  const [monitorPlatformRowData, setMonitorPlatformRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [monitorInternalRowData, setMonitorInternalRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [platformLoader, setPlatformLoader] = useState(false);
  const [internalLoader, setInternalLoader] = useState(false);
  const [platformsList, setPlatformsList] = useState()
  const setLoadingInProps = props.setLoading;
  const config_columns = PLATFORM_CONFIGURATION_COLUMNS;
  // const monitor_columns = MONITORING_COLUMNS;

  useEffect(() => {
    // setLoadingInProps(true);
    setPlatformLoader(true);
    setInternalLoader(true);
    axiosWithAuth.get("/monitoring/platform")
      .then(data => {
        console.log('Inside monitoring')
        let platformsList = []
        for (let singlePlatform of data.data) {
          if (!platformsList.includes(singlePlatform['platform']))
            platformsList.push(singlePlatform['platform'])
        }
        setPlatformsList(platformsList)
        setMonitorPlatformRowData(data.data)
        setPlatformLoader(false);
        // setLoadingInProps(false);
      }).catch(err => {
        console.error(err)
      });
    axiosWithAuth.get("/monitoring/internal")
      .then(data => {
        console.log('Inside monitoring internal')
        setMonitorInternalRowData(data.data)
        setInternalLoader(false);
        // setLoadingInProps(false);
      }).catch(err => {
        console.error(err)
      });
  }, []);


  // const cellEditor = (options) => {
  //   if (options.field === 'spro_upload' || options.field === 'validate')
  //     return textEditor(options);
  // }

  // const textEditor = (options) => {
  //   return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  // }

  // const onCellEditComplete = (e) => {
  //   let { rowData, newValue, field, originalEvent: event } = e;

  //   switch (field) {
  //     default:
  //       if (newValue.trim().length > 0)
  //         rowData[field] = newValue;
  //       else
  //         event.preventDefault();
  //       break;
  //   }
  // }


  // const dynamicColumnsMonitoring = config_columns.map((col, i) => {
  //   return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
  // })
  const PlatformRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={platformsList} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select a Platform" className="p-column-filter" showClear />;
  }


  const dynamicColumnsPlatformMonitoring = MONITORING_COLUMNS.map((col, i) => {
    if (col.field === 'platform')
      return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} filter={col.filter} filterField={col.filterField} />
    else
      return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} filter={col.filter} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} filterElement={col.filterElement} />
  })

  // const dynamicColumnsInternalMonitoring = config_columns.map((col, i) => {
  //   return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
  // })


  return (
    <div className="content-container">
      <div style={{ maxWidth: "100%", maxHeight: "100%", minHeight: "50%" }}>
        <TabView>
          {check_admin_access(groups) && <TabPanel header="Platform Monitoring">
            <DataTable value={monitorPlatformRowData} showGridlines dataKey="_key" loading={platformLoader} filterDisplay="row"
              paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll" stripedRows sortField="update_date"
            >
              {dynamicColumnsPlatformMonitoring}
            </DataTable>
          </TabPanel>}

          {check_admin_access(groups) && <TabPanel header="Internal Monitoring">
            <DataTable value={monitorInternalRowData} showGridlines dataKey="_key" loading={internalLoader}
              paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll" stripedRows sortField="update_date"
            >
              {dynamicColumnsPlatformMonitoring}
            </DataTable>
          </TabPanel>}

        </TabView>
      </div>
    </div>
  );
};

export default Monitoring;
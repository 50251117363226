import axios from "axios";
import { Auth } from "aws-amplify";


import env from '../env.json'

// const [token,setToken] = useLocalStorage('token',null);

console.log()
let axiosWithAuth = axios
    .create({
        baseURL: env.api_url,
    });
axiosWithAuth.interceptors.request.use((req) => {
    let token = JSON.parse(localStorage.getItem('token'));
    req.headers.Authorization = `Bearer ${token}`
    return req;
}, (err) => {
    return Promise.reject(err)
})
axiosWithAuth.interceptors.response.use((res) => {
    // console.log('AxiosWithAuthErr:')
    // console.log(res)
    return res
}, (err) => {
    console.log('AxiosWithAuthErr:')
    console.error(err)
    if (err.response.status === 401) {
        // setToken(null)
        localStorage.removeItem('token')
        Auth.signOut()
    }
    return Promise.reject(err)
})
export default axiosWithAuth;
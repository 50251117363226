import { isNil } from "lodash";
import { array_to_string, string_to_array } from "./ArrayHelper";

const check_analytics_access = (groups) => {
    return (check_tab_access(groups, 'Analytics') || check_tab_access(groups, 'Admin'))
}

const check_ecs_access = (groups) => {
    return (check_tab_access(groups, 'ECS') || check_tab_access(groups, 'Admin'))
}

const check_monitoring_access = (groups) => {
    return (check_tab_access(groups, 'Monitoring') || check_tab_access(groups, 'Admin'))
}

const check_abs_access = (groups) => {
    return (check_tab_access(groups, 'ABS') || check_tab_access(groups, 'Admin'))
}

const check_admin_access = (groups) => {
    return check_tab_access(groups, 'Admin')
}

const conv_groups_array_for_state = (groups) => {
    return array_to_string(groups);
}

const check_tab_access = (groups, tab_name) => {
    if (isNil(groups)){
        return false;
    }
    groups = string_to_array(groups, ',');
    return groups.includes(tab_name);
}

export { conv_groups_array_for_state, check_analytics_access, check_ecs_access, check_monitoring_access, check_admin_access, check_tab_access,check_abs_access }
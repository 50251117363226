import React, { useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';
import { FormContext } from '../ClimateToolboxGeneration';

export default function CompanyInformation() {
    const { formik } = useContext(FormContext);
    const isFormFieldValid = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return !!(formik.touched[name] && formik.errors[firstLevel][secondLevel]);
    }
    const getFormErrorMessage = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[firstLevel][secondLevel]}</small>;
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Firmeninformation</h4>
                <form>
                    <div className="p-fluid">
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="information.headquarters" name="information.headquarters" value={formik.values.information.headquarters} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.headquarters') })} placeholder="e.g. Frankfurt am Main" tooltip="Der Firma Hauptsitz" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.headquarters" className={classNames({ 'p-error': isFormFieldValid('information.headquarters') })}>Firmensitz*</label>
                            </span>
                            {getFormErrorMessage('information.headquarters')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="information.otherLocations" name="information.otherLocations" value={formik.values.information.otherLocations} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.otherLocations') })} placeholder="e.g. Frankfurt am Main, Berlin, ..." tooltip="Kommagetrennte Werte für andere Firma Standorte" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.otherLocations" className={classNames({ 'p-error': isFormFieldValid('information.otherLocations') })}>Sonstige relevante Standorte</label>
                            </span>
                            {getFormErrorMessage('information.otherLocations')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputMask id="information.foundedIn" name="information.foundedIn" value={formik.values.information.foundedIn} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.foundedIn') })} mask="9999" tooltip="Das Gründungsjahr der Firma, e.g. 1999" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.foundedIn" className={classNames({ 'p-error': isFormFieldValid('information.foundedIn') })}>Gegründet</label>
                            </span>
                            {getFormErrorMessage('information.foundedIn')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputMask id="information.naceCode" name="information.naceCode" value={formik.values.information.naceCode} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.naceCode') })} mask="a99.9.9" tooltip="Der komplette NACE-Code, e.g. J62.0.1" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.naceCode" className={classNames({ 'p-error': isFormFieldValid('information.naceCode') })}>Wirtschaftssektor</label>
                            </span>
                            {getFormErrorMessage('information.naceCode')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="information.employees" className={classNames({ 'p-error': isFormFieldValid('information.employees') })}>Anzahl Mitarbeiter</label>
                            <InputNumber id="information.employees" name="information.employees" value={formik.values.information.employees} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.employees') })} tooltip="Gesamtzahl der Beschäftigten im Firma" tooltipOptions={{mouseTrack: true}} min={0}/>
                            {getFormErrorMessage('information.employees')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="information.districtCourt" name="information.districtCourt" value={formik.values.information.districtCourt} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.districtCourt') })} placeholder="e.g. Amtsgericht Frankfurt am Main" tooltip="Zuständiges Amtsgericht für die Firma" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.districtCourt" className={classNames({ 'p-error': isFormFieldValid('information.districtCourt') })}>Zuständiges Amtsgericht*</label>
                            </span>
                            {getFormErrorMessage('information.districtCourt')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="information.registrationNumber" name="information.registrationNumber" value={formik.values.information.registrationNumber} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('information.registrationNumber') })} tooltip="Die Handelsregisternummer der Firma" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="information.registrationNumber" className={classNames({ 'p-error': isFormFieldValid('information.registrationNumber') })}>Handelsregisternummer*</label>
                            </span>
                            {getFormErrorMessage('information.registrationNumber')}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, { useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { FormContext } from '../ClimateToolboxGeneration';

export default function CompanyAddress() {
    const { formik } = useContext(FormContext);
    const isFormFieldValid = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return !!(formik.touched[name] && formik.errors[firstLevel][secondLevel]);
    }
    const getFormErrorMessage = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[firstLevel][secondLevel]}</small>;
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Firmenadresse</h4>
                <form>
                    <div className="p-fluid">
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="address.companyName" name="address.companyName" value={formik.values.address.companyName} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('address.companyName') })} placeholder="e.g. Exaloan AG" tooltip="Der vollständige Firmenname" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="address.companyName" className={classNames({ 'p-error': isFormFieldValid('address.companyName') })}>Firmenname*</label>
                            </span>
                            {getFormErrorMessage('address.companyName')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="address.street" name="address.street" value={formik.values.address.street} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('address.street') })} tooltip="Die Adresse der Firma" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="address.street" className={classNames({ 'p-error': isFormFieldValid('address.street') })}>Straße*</label>
                            </span>
                            {getFormErrorMessage('address.street')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="address.houseNumber" name="address.houseNumber" value={formik.values.address.houseNumber} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('address.houseNumber') })} tooltip="Die Hausnummer der Firma" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="address.houseNumber" className={classNames({ 'p-error': isFormFieldValid('address.houseNumber') })}>Hausnummer*</label>
                            </span>
                            {getFormErrorMessage('address.houseNumber')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="address.postCode" name="address.postCode" value={formik.values.address.postCode} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('address.postCode') })} tooltip="Die Postleitzahl  der Firma" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="address.postCode" className={classNames({ 'p-error': isFormFieldValid('address.postCode') })}>Postleitzahl*</label>
                            </span>
                            {getFormErrorMessage('address.postCode')}
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <InputText id="address.city" name="address.city" value={formik.values.address.city} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('address.city') })} tooltip="Der Firma Stadt" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="address.city" className={classNames({ 'p-error': isFormFieldValid('address.city') })}>Stadt*</label>
                            </span>
                            {getFormErrorMessage('address.city')}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, { useContext } from 'react';
import { FormContext } from '../ClimateToolboxGeneration';

export default function SampleReport() {
    const { platform, esgReportName, reqID } = useContext(FormContext);

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Vorschau Report</h4>
                <div className="flex justify-content-center">
                    <iframe
                        title='Sample Report'
                        style={{ width: "100%", height: "842px" }}
                        // src= {'https://esg-generated-reports.s3.eu-central-1.amazonaws.com/' + platform + '/' + reqID + '/' + esgReportName + '.pdf'}
                        src= {'https://esg-generated-reports.s3.eu-central-1.amazonaws.com/V2_Klimareport_Template_Exaloan_AG_Sample_Data.pdf'}
                    />
                </div>
            </div>
        </div>
    )
}
import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { isNil } from "lodash";
import { useAuth } from '../../hooks/useAuth';

import { check_tab_access } from '../../helpers/AccessHelper'
import "./analytics.css"

function Analytics(props) {
  const { groups, platform_config } = useAuth();
  const [platform_analytics, setPlatformAnalytics] = useState(filter_analytics());

  let platform_analytics_default = [
    {
      "title": "Climate Toolbox",
      "iframe_title": "Climate_Toolbox_New",
      "url": "https://app.powerbi.com/reportEmbed?reportId=9a4773ba-54ea-4e24-9ab4-23300a60eb86&autoAuth=true&ctid=faf87534-ed1d-4742-84d2-5cedba6d215c",
      "group": "Analytics"
    },
    {
      "title": "Analytics Dashboard",
      "iframe_title": "Exaport_Analytics_dashboards - Overview",
      "url": "https://app.powerbi.com/reportEmbed?reportId=1ba57708-cc49-4772-85bd-dd3b53e9cb6c&autoAuth=true&ctid=faf87534-ed1d-4742-84d2-5cedba6d215c&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWQtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D%22",
      "group": "Analytics"
    }
  ]

  function filter_analytics() {
    let analytics_tabs = []
    let platform_analytics_configuration = []

    // Selecting from Platform Configuration if it exists, otherwise using default
    if (!isNil(platform_config?.platform_analytics)) {
      platform_analytics_configuration = platform_config.platform_analytics;
    } else {
      platform_analytics_configuration = platform_analytics_default;
    }

    for (let index in platform_analytics_configuration) {
      let item = platform_analytics_configuration[index];
      if (check_tab_access(groups, item['group'])) {
        analytics_tabs.push(item)
      }
    }
    console.log(analytics_tabs);
    return analytics_tabs;
  }


  function with_or_without_clip(item){
    if ((item['id'] == 'jnyTFLRc') | ('navigation_hide' in item & item['navigation_hide'])){
    return <iframe title={item['iframe_title']} width="100%"  height="100%" src={item['url']} frameBorder="0" allowFullScreen={true}
    style={{ minHeight: "640px", clipPath: "polygon(0% 0%,100% 0%,100% 93%,0% 93%)" }}></iframe>
  }else{
    return <iframe title={item['iframe_title']} width="100%"  height="100%" src={item['url']} frameBorder="0" allowFullScreen={true}
    style={{ minHeight: "640px" }}></iframe>
  }
  }


  return (

    <div className="content-container" id="analytics-container">
        <TabView>
          {platform_analytics.map((item, index) => <TabPanel header={item['title']} key={index}>
            {!isNil(item['downloads']) &&
              // <div className='div-flexbox' key={index}>{Object.entries(item['downloads']).map((download_item, download_index) => <a href={download_item[1]} className='p-button'><b>{'Download '+ download_item[0]}</b></a>)}</div>}
              <div className='div-flexbox' key={index}>{Object.entries(item['downloads']).map((download_item, download_index) => <a href={download_item[1]} className='p-button'><b>{'Download ' + download_item[1].split(".").pop().toUpperCase()}</b></a>)}</div>}
              {with_or_without_clip(item)}
            {/* <iframe title={item['iframe_title']} width="100%"  height="100%" src={item['url']} frameBorder="0" allowFullScreen={true}
            style={{ minHeight: "640px", clipPath: "polygon(0% 0%,100% 0%,100% 95%,0% 95%)" }}></iframe> */}
          </TabPanel>)}
        </TabView>
    </div>
  )

};

export default Analytics;


import React, { useContext } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { FormContext } from '../ClimateToolboxGeneration';

export default function FinancialIndicators() {
    const { formik } = useContext(FormContext);
    const isFormFieldValid = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return !!(formik.touched[name] && formik.errors[firstLevel][secondLevel]);
    }
    const getFormErrorMessage = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[firstLevel][secondLevel]}</small>;
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Finanzkennzahlen</h4>
                <form>
                    <div className="p-fluid grid formgrid">
                        <div className="field col-12">
                            <span className="p-float-label">
                                <Calendar id="financial.balanceSheetDate" name="financial.balanceSheetDate" value={formik.values.financial.balanceSheetDate} onChange={formik.handleChange} dateFormat="dd/mm/yy" mask="99/99/9999" showIcon tooltip="Das Datum, an dem die Bilanz erstellt wird" tooltipOptions={{mouseTrack: true}}/>
                                <label htmlFor="financial.balanceSheetDate" className={classNames({ 'p-error': isFormFieldValid('financial.balanceSheetDate') })}>Bilanzstichtag*</label>
                                {getFormErrorMessage('financial.balanceSheetDate')}
                            </span>
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.assets" className={classNames({ 'p-error': isFormFieldValid('financial.assets') })}>Aktiva*</label>
                            <InputNumber inputId="financial.assets" name="financial.assets" value={formik.values.financial.assets} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.assets') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Aktiva der Firma zum Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.assets')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.revenue" className={classNames({ 'p-error': isFormFieldValid('financial.revenue') })}>Umsatz</label>
                            <InputNumber inputId="financial.revenue" name="financial.revenue" value={formik.values.financial.revenue} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.revenue') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Firmenumsatz am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.revenue')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.equityCapital" className={classNames({ 'p-error': isFormFieldValid('financial.equityCapital') })}>Eigenkapital</label>
                            <InputNumber inputId="financial.equityCapital" name="financial.equityCapital" value={formik.values.financial.equityCapital} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.equityCapital') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Eigenkapital der Firma am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.equityCapital')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.financialDebt" className={classNames({ 'p-error': isFormFieldValid('financial.financialDebt') })}>Finanzverbindlichkeiten</label>
                            <InputNumber inputId="financial.financialDebt" name="financial.financialDebt" value={formik.values.financial.financialDebt} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.financialDebt') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Alle zum Bilanzstichtag bestehenden verzinslichen Verpflichtungen" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.financialDebt')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.ebitda" className={classNames({ 'p-error': isFormFieldValid('financial.ebitda') })}>EBITDA</label>
                            <InputNumber inputId="financial.ebitda" name="financial.ebitda" value={formik.values.financial.ebitda} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.ebitda') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Die EBITDA der Firma am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.ebitda')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.ebit" className={classNames({ 'p-error': isFormFieldValid('financial.ebit') })}>EBIT</label>
                            <InputNumber inputId="financial.ebit" name="financial.ebit" value={formik.values.financial.ebit} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.ebit') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Die EBIT der Firma am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.ebit')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.profit" className={classNames({ 'p-error': isFormFieldValid('financial.profit') })}>Gewinn</label>
                            <InputNumber inputId="financial.profit" name="financial.profit" value={formik.values.financial.profit} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.profit') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Die Gewinn der Firma am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.profit')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.ebitdaMargin" className={classNames({ 'p-error': isFormFieldValid('financial.ebitdaMargin') })}>EBITDA-Marge</label>
                            <InputNumber inputId="financial.ebitdaMargin" name="financial.ebitdaMargin" value={formik.values.financial.ebitdaMargin} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.ebitdaMargin') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Die EBITDA-Marge der Firma am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.ebitdaMargin')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.indebtedness" className={classNames({ 'p-error': isFormFieldValid('financial.indebtedness') })}>Verschuldungsgrad</label>
                            <InputNumber inputId="financial.indebtedness" name="financial.indebtedness" value={formik.values.financial.indebtedness} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.indebtedness') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Verhältnis von Schulden zu Eigenkapital am Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.indebtedness')}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="financial.operativeCashflow" className={classNames({ 'p-error': isFormFieldValid('financial.operativeCashflow') })}>Operativer Cashflow</label>
                            <InputNumber inputId="financial.operativeCashflow" name="financial.operativeCashflow" value={formik.values.financial.operativeCashflow} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('financial.operativeCashflow') })} mode="currency" currency="EUR" locale="de-DE" tooltip="Operativer Cashflow der Firma zum Bilanzstichtag" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('financial.operativeCashflow')}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { USER_COLUMNS } from './userColumns'


export const Users = (props) => {
    const [users, setUsers] = useState(props.users)

    // const { platformName } = useParams()

    // useEffect(() => {
    //     setUsers(props.users)
    //     // axiosWithAuth.get(`/platforms/${platformName}/users`).then((response) => {
    //     //     let data = response.data;
    //     //     console.log(data);
    //     //     setUsers(data);
    //     // }).catch((err) => {
    //     //     alert(err);
    //     // })
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const platformUserColumns = USER_COLUMNS.map((col, i) => {
        if (col.field in users[0]) {
            return <Column key={col.field} field={col.field} header={col.header} style={col.style} body={col.body} sortable={col.sortable} align={col.align} />
        }
    })
    return (
        <DataTable value={users} dataKey="email"
            paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll" stripedRows sortField="email">
            {platformUserColumns}
        </DataTable>
    );
}
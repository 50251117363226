import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { Chip } from 'primereact/chip';

import { check_tab_access } from '../../helpers/AccessHelper'
// import home from '../../assets/images/home.png';
import exaLogo from "../../assets/images/exaport.png";
import fileupload from '../../assets/images/fileupload.png';
import tracking from '../../assets/images/tracking.png';
import analytics from '../../assets/images/analytics.png';
import ecs from "../../assets/images/ecs.png";
import monitoring from "../../assets/images/monitoring.png";
import calculator from "../../assets/images/calculator.png";
import settings from '../../assets/images/settings.png';
import dashboard from '../../assets/images/overview.png';
import logout from '../../assets/images/logout.png';
import "./sidebar.css";

function Sidebar(props) {
    const { groups } = useAuth();
    console.log(props);
    const [nav_menu_items, setNavMenuItems] = useState([]);
    const user = useLocalStorage("user", null);
    const platform = useLocalStorage("platform", null);

    useEffect(() => {
        function filter_user() {
            let nav_menu_items_ = [
                { id: 1, link_to: "/", img_src: fileupload, text: "File Upload" },
                { id: 2, link_to: "/filetracking", img_src: tracking, text: "File Tracking" },
                { id: 3, group: "Analytics", link_to: "/analytics", img_src: analytics, text: "Platform Analytics" },
                { id: 4, group: "ECS", link_to: "/climate-toolbox", img_src: ecs, text: "ESG Toolbox" },
                { id: 9, group: "ABS", link_to: "/abs", img_src: calculator, text: "ABS Calculator" },
                { id: 5, group: "Admin", link_to: "/admin", img_src: monitoring, text: "Monitoring" },
                { id: 6, group: "Admin", link_to: "/platforms", img_src: settings, text: "Platforms" },
                { id: 7, group: "Admin", link_to: "/dashboard", img_src: dashboard, text: "Dashboard" },
                { id: 8, link_to: "/logout", img_src: logout, text: "Logout" },
            ];
            setNavMenuItems(nav_menu_items_.filter(obj => check_tab_access(groups, obj.group || "User")));
        }
        filter_user()

    }, []);

    // filter_user();
    return (
        <div id='sidebar-container' className='nav-menu-container'>
            <div id="sidebar" className='nav-menu'>
                <div className="nav-menu-item">
                    <Link to="/" className='nav-menu-link'>
                        <div className='nav-menu-app-name'>
                            {/* <img alt="" className="home" src= {home}/> <img alt="" className="logo2" src= {exaLogo}/> */}
                            <img alt="" className="logo2" src={exaLogo} />
                        </div>
                    </Link>
                </div>
                {nav_menu_items.map(item => (
                    <div className="nav-menu-item" key={item.id}>
                        <div className='nav-menu-button' >
                            <NavLink to={item.link_to} className={(navData) => (navData.isActive ? "active-link nav-menu-link" : 'nav-menu-link')} >
                                <img alt="" className="icon" src={item.img_src} />
                                <span className='nav-menu-text'>{item.text}</span>
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
            <div className='user-container'>
                <div className='user-item'>
                    <Chip className="mr-2 user-chip" icon="pi pi-user" label={user} />
                </div>
                <div className='user-item'>
                    <Chip className="mr-2 user-chip" icon="pi pi-briefcase" label={platform} />
                </div>
            </div>
        </div>

    );
};

export default Sidebar;
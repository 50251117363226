import React, { useContext } from "react";
import CompanyAddress from "./CompanyAddress";
import CompanyInformation from "./CompanyInformation";
import FinancialIndicators from "./FinancialIndicators";
import Energy from "./Energy"
import ESG from "./ESG";
import FormResults from "./FormResults";
import SampleReport from './SampleReport';
import { FormContext } from '../ClimateToolboxGeneration';

function Step() {
    const { activeIndex } = useContext(FormContext);
    let stepContent;
    switch (activeIndex) {
        case 0:
            stepContent = <CompanyAddress />;
            break;
        case 1:
            stepContent = <CompanyInformation />;
            break;
        case 2:
            stepContent = <FinancialIndicators />;
            break;
        case 3:
            stepContent = <Energy />;
            break;
        case 4:
            stepContent = <ESG />;
            break;
        case 5:
            stepContent = <FormResults />;
            break;
        case 6:
            stepContent = <SampleReport />;
            break;
        default:
            break;
    }

    return stepContent;
}

export default Step;
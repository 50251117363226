import React, { useContext } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { FormContext } from '../ClimateToolboxGeneration';

export default function ESG() {
    const { formik } = useContext(FormContext);
    const isFormFieldValid = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return !!(formik.touched[name] && formik.errors[firstLevel][secondLevel]);
    }
    const getFormErrorMessage = (name) => {
        let firstLevel, secondLevel = name.split(".")
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[firstLevel][secondLevel]}</small>;
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Environmental, Social, Governance</h4>
                <form>
                    <div className="p-fluid">
                        <div className="field col-12">
                            <label htmlFor="esg.biodiversity" className={classNames({ 'p-error': isFormFieldValid('esg.biodiversity') })}>Biodiversität</label>
                            <InputNumber id="esg.biodiversity" name="esg.biodiversity" value={formik.values.esg.biodiversity} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('esg.biodiversity') })} tooltip="Aktivitäten mit negative Auswirkungen auf die biologische Vielfalt" tooltipOptions={{mouseTrack: true}} min={0}/>
                            {getFormErrorMessage('esg.biodiversity')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="esg.waterEmissions" className={classNames({ 'p-error': isFormFieldValid('esg.waterEmissions') })}>Emissionen in Wasser</label>
                            <InputNumber id="esg.waterEmissions" name="esg.waterEmissions" value={formik.values.esg.waterEmissions} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('esg.waterEmissions') })} mode="decimal" suffix=" kg" tooltip="Wasser-Emissionen in kg" tooltipOptions={{mouseTrack: true}} min={0}/>
                            {getFormErrorMessage('esg.waterEmissions')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="esg.hazardousWaste" className={classNames({ 'p-error': isFormFieldValid('esg.hazardousWaste') })}>Anteil gefährlicher Abfälle</label>
                            <InputNumber id="esg.hazardousWaste" name="esg.hazardousWaste" value={formik.values.esg.hazardousWaste} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('esg.hazardousWaste') })} mode="decimal" suffix=" %" tooltip="Anteil gefährlicher Abfälle in Prozent" tooltipOptions={{mouseTrack: true}} min={0} max={100}/>
                            {getFormErrorMessage('esg.hazardousWaste')}
                        </div>
                        <div className="field-checkbox col-12 justify-content-between">
                            <label htmlFor="esg.ungcPrincipals" className={classNames({ 'p-error': isFormFieldValid('esg.ungcPrincipals') })}>Verstöße gegen UNGC Grundsätze</label>
                            <Checkbox inputId="esg.ungcPrincipals" name="esg.ungcPrincipals" checked={formik.values.esg.ungcPrincipals} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('esg.ungcPrincipals') })} tooltip="Hat die Firma gegen UNGC Grundsätze verstoßen?" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('esg.ungcPrincipals')}
                        </div>
                        <div className="field-checkbox col-12 justify-content-between">
                            <label htmlFor="esg.ungcMonitoring" className={classNames({ 'p-error': isFormFieldValid('esg.ungcMonitoring') })}>Überwachung der UNGC Grundsätze</label>
                            <Checkbox inputId="esg.ungcMonitoring" name="esg.ungcMonitoring" checked={formik.values.esg.ungcMonitoring} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('esg.ungcMonitoring') })} tooltip="Verfolgt die Firma die UNGC Grundsätze?" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('esg.ungcMonitoring')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="esg.genderPayGap" className={classNames({ 'p-error': isFormFieldValid('esg.genderPayGap') })}>Geschlechtsspezifisches Verdienstgefälle</label>
                            <InputNumber id="esg.genderPayGap" name="esg.genderPayGap" value={formik.values.esg.genderPayGap} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('esg.genderPayGap') })} mode="decimal" suffix=" %" tooltip="Durchschnittlicher Bruttostundenverdienst von Männern minus durchschnittlicher Bruttostundenverdienst von Frauen, ausgedrückt in Prozent des durchschnittlichen Bruttostundenverdienstes von Männern." tooltipOptions={{mouseTrack: true}} min={0} max={100}/>
                            {getFormErrorMessage('esg.genderPayGap')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="esg.boardGenderDiversity" className={classNames({ 'p-error': isFormFieldValid('esg.boardGenderDiversity') })}>Geschlechtervielfalt in den Leitungs- und Kontrollorganen</label>
                            <InputNumber id="esg.boardGenderDiversity" name="esg.boardGenderDiversity" value={formik.values.esg.boardGenderDiversity} onValueChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('esg.boardGenderDiversity') })} mode="decimal" suffix=" %" tooltip="Verhältnis von Frauen und Männern in den Leitungs- und Kontrollorganen, in Prozent" tooltipOptions={{mouseTrack: true}} min={0} max={100}/>
                            {getFormErrorMessage('esg.boardGenderDiversity')}
                        </div>
                        <div className="field-checkbox col-12 justify-content-between">
                            <label htmlFor="esg.controversialWeapons" className={classNames({ 'p-error': isFormFieldValid('esg.controversialWeapons') })}>Umstrittene Waffen</label>
                            <Checkbox inputId="esg.controversialWeapons" name="esg.controversialWeapons" checked={formik.values.esg.controversialWeapons} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('esg.controversialWeapons') })} tooltip="Engagement in umstrittenen Waffen" tooltipOptions={{mouseTrack: true}}/>
                            {getFormErrorMessage('esg.controversialWeapons')}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Sidebar from "../navigation/Sidebar";
import { useEffect } from "react";
import { Auth} from "aws-amplify";

export const ProtectedRoute = ({ children }) => {
  const { token,logout } = useAuth();
  useEffect(() => {
    const interval = setInterval(() => {
      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {console.log(Date());console.log(user)})
    .catch(err => {console.log(err);logout();})
    }, 10*1000);
    return () => clearInterval(interval);
  }, []);


  if (!token) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }


  return (
    <div className='main_with_side_bar'>
    <nav className="side">
              <Sidebar />
            </nav>
  <div className='main_content'>
    <Outlet/>
    </div>
    </div>
    );
};
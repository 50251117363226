import _ from "lodash";
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import '../../platforms/platforms.css';
import { PLATFORM_OVERVIEW_COLUMNS } from "./platformOverviewColumns";

function PlatformsOverview(props) {
    const [platformOverviewData, setPlatformOverviewData] = useState(props.platformOverview);

    const dynamicColumnsPlatformOverview = PLATFORM_OVERVIEW_COLUMNS.map((col, i) => {
        if (col.field == 'platform') {
            return <Column key={col.field} field={row => <NavLink style={{ color: 'inherit', textDecoration: 'none' }} to={`${_.kebabCase(row[col.field])}`}><Button>{row[col.field]}</Button></NavLink>} header={col.header} body={col.body} sortable={col.sortable} />
        } else {
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} />
        }
    })

    let headerColumns = <ColumnGroup>
        <Row>
            <Column header="Platform" rowSpan={3} />
            <Column header="Number of Loans" colSpan={3} />
            <Column header="Origination Volume*" colSpan={1} />
            <Column header="Currency" colSpan={1} />
            <Column header="Default Rate" colSpan={2} />
            <Column header="Average Term" colSpan={2} />
            <Column header="Average Interest Rate" colSpan={2} />
            <Column header="Average Loan Size*" colSpan={2} />
            <Column header="Last Updated (YYYY-MM-DD)" colSpan={2} />
            <Column header="Recovery Rate" colSpan={2} />
        </Row>
        <Row>
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="Total" colSpan={1} />
            <Column header="Inception" colSpan={1} />
            <Column header="(Original)" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
            <Column header="SME" colSpan={1} />
            <Column header="CONS" colSpan={1} />
        </Row>
    </ColumnGroup>;

    let euroFormat = new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'EUR',
    });

    const formatCurrency = (value) => {
        return `${euroFormat.format(value)}`;
    }

    const columnTotal = (key) => {
        let total = 0;
        for (let platform of platformOverviewData) {
            total += _.get(platform, key, 0)
        }
        return total;
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" colSpan={1} footerStyle={{ textAlign: 'right' }} />
            <Column footer={columnTotal('SME.loan_count')} />
            <Column footer={columnTotal('CONSUMER.loan_count')} />
            <Column footer={columnTotal('total_loan_count')} />
            <Column footer={formatCurrency(columnTotal('total_origination_volume'))} />
        </Row>
    </ColumnGroup>;

    return (
        <div>

        
        <DataTable value={platformOverviewData} showGridlines dataKey="_key" headerColumnGroup={headerColumns} footerColumnGroup={footerGroup}
            paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5, 10, 20, 50]} responsiveLayout="scroll"
            stripedRows>
            {dynamicColumnsPlatformOverview}
        </DataTable>
        <div>*in EUR</div>
        </div>
    );
};

export default PlatformsOverview;
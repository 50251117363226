export const PLATFORM_CONFIGURATION_COLUMNS = [
    {
        header: 'Platform',
        field: 'platform',
    },
    {
        header: 'Validation',
        field: 'validate',
    },
    {
        header: 'S-Pro',
        field: 'spro_upload',
    },
    {
        header: 'Valid Upload Data Types',
        field: 'data_types',
    }

]

export const MONITORING_COLUMNS = [
    {
        header: 'Platform',
        field: 'platform',
        
        filter: 'agTextColumnFilter',
    },
    {
        header: 'Upload Date',
        field: 'update_date',
        
    },
    {
        header: 'Loans Uploaded',
        field: 'numOfRecords',
        
    },
    {
        header: 'Collection Uploaded To',
        field: 'collection_name',
        
    },
    {
        header: 'Uploaded From',
        field: 'source',
        
    },
    {
        header: 'Req ID',
        field: 'request_id',
        
        filter: 'agTextColumnFilter',
    },
    {
        header: 'Errors',
        field: 'errors',
        
    },
    // {
    //     header: 'Upload History',
    //     valueGetter: 'upload_history',
    //     editable: false,
    // }

]
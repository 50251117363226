import React, { useContext } from 'react';
import { FormContext } from '../ClimateToolboxGeneration';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axiosWithAuth from '../../../helpers/AxiosHelper';

export default function FormResults() {
    const { formik, esgReportName, setESGReportName, setReqID, platform } = useContext(FormContext);

    const getValue = (value) => {
        let value_string = '-';
        if (value) {
            value_string = value;
        }
        return value_string;
    }

    const getDate = () => {
        let date = new Date().toString();
        if (formik.values.financial.balanceSheetDate) {
            date = formik.values.financial.balanceSheetDate;
        }
        return date.toString().substring(3, 15);
    }

    const getBool = (checkBool) => {
        let boolValue = "Nein";
        if (checkBool) {
            boolValue = "Ja";
        }
        return boolValue;
    }

    const generateESGReport = () => {
        const formData = new FormData();
        formData.append("company_data", JSON.stringify(formik.values, null, 2));
        formData.append("esg_report_name", esgReportName)
        formData.append("platform_name", platform)
        
        let v = axiosWithAuth.post('/generate-esg-report', formData).then((response) => {
            setReqID(response.data.id);
            console.log("ESG Report Generated");
        }).catch((err) => {
            console.log(err);
            if (err.message) {
                console.log(err.message);
            }
        });
    };

    // sample of adding CO2 generation to frontend - 
    const generateCO2 = () => {
        const formData = new FormData();
        formData.append("company_name", getValue(formik.values.address.companyName));
        formData.append("sector_NACE_1", getValue(formik.values.information.naceCode)[0])
        formData.append("revenue", getValue(formik.values.financial.revenue))
        formData.append("balance_sheet_date", getValue(formik.values.financial.balanceSheetDate))
        
        let v = axiosWithAuth.post('/generate-co2', formData).then((response) => {
            setReqID(response.data.id);
            console.log("CO2 Score Generated");
        }).catch((err) => {
            console.log(err);
            if (err.message) {
                console.log(err.message);
            }
        });
        console.log(v)
    };

    return (
        <div className="flex justify-content-center">
            <div className="card">
                <h4 className="text-center">Übersicht</h4>
                <div className="grid formgrid">
                    <Accordion className="col-4">
                        <AccordionTab header="Firmenadresse">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <label htmlFor="address.companyName" className="address.companyName">Firmenname:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="address.companyName" className="address.companyName"><b>{getValue(formik.values.address.companyName)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="address.street" className="address.street">Straße:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="address.street" className="address.street"><b>{getValue(formik.values.address.street)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="address.houseNumber" className="address.houseNumber">Hausnummer:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="address.houseNumber" className="address.houseNumber"><b>{getValue(formik.values.address.houseNumber)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="address.postCode" className="address.postCode">Postleitzahl:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="address.postCode" className="address.postCode"><b>{getValue(formik.values.address.postCode)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="address.city" className="address.city">Stadt:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="address.city" className="address.city"><b>{getValue(formik.values.address.city)}</b></label>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Accordion className="col-4">
                        <AccordionTab header="Firmeninformation">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <label htmlFor="information.headquarters" className="information.headquarters">Firmensitz:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.headquarters" className="information.headquarters"><b>{getValue(formik.values.information.headquarters)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.otherLocations" className="information.otherLocations">Sonstige relevante Standorte:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.otherLocations" className="information.otherLocations"><b>{getValue(formik.values.information.otherLocations)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.foundedIn" className="information.foundedIn">Gegründet:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.foundedIn" className="information.foundedIn"><b>{getValue(formik.values.information.foundedIn)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.naceCode" className="information.naceCode">Wirtschaftssektor:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.naceCode" className="information.naceCode"><b>{getValue(formik.values.information.naceCode)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.employees" className="information.employees">Anzahl Mitarbeiter:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.employees" className="information.employees"><b>{getValue(formik.values.information.employees)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.districtCourt" className="information.districtCourt">Zuständiges Amtsgericht:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.districtCourt" className="information.districtCourt"><b>{getValue(formik.values.information.districtCourt)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="information.registrationNumber" className="information.registrationNumber">Handelsregister-nummer:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="information.registrationNumber" className="information.registrationNumber"><b>{getValue(formik.values.information.registrationNumber)}</b></label>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Accordion className="col-4">
                        <AccordionTab header="Finanzkennzahlen">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <label htmlFor="financial.balanceSheetDate" className="financial.balanceSheetDate">Bilanzstichtag:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    {/* <label htmlFor="financial.balanceSheetDate" className="financial.balanceSheetDate"><b>{formik.values.financial.balanceSheetDate.toString().substring(3, 15)}</b></label> */}
                                    <label htmlFor="financial.balanceSheetDate" className="financial.balanceSheetDate"><b>{getDate()}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.assets" className="financial.assets">Aktiva:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.assets" className="financial.assets"><b>{getValue(formik.values.financial.assets)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.revenue" className="financial.revenue">Umsatz:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.revenue" className="financial.revenue"><b>{getValue(formik.values.financial.revenue)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.equityCapital" className="financial.equityCapital">Eigenkapital:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.equityCapital" className="financial.equityCapital"><b>{getValue(formik.values.financial.equityCapital)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.financialDebt" className="financial.financialDebt">Finanzverbindlich-keiten:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.financialDebt" className="financial.financialDebt"><b>{getValue(formik.values.financial.financialDebt)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.ebitda" className="financial.ebitda">financial.ebitda:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.ebitda" className="financial.ebitda"><b>{getValue(formik.values.financial.ebitda)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.ebit" className="financial.ebit">financial.ebit:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.ebit" className="financial.ebit"><b>{getValue(formik.values.financial.ebit)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.profit" className="financial.profit">Gewinn:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.profit" className="financial.profit"><b>{getValue(formik.values.financial.profit)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.ebitdaMargin" className="financial.ebitdaMargin">financial.ebitda-Marge:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.ebitdaMargin" className="financial.ebitdaMargin"><b>{getValue(formik.values.financial.ebitdaMargin)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.indebtedness" className="financial.indebtedness">Verschuldungsgrad:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.indebtedness" className="financial.indebtedness"><b>{getValue(formik.values.financial.indebtedness)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="financial.operativeCashflow" className="financial.operativeCashflow">Operativer Cashflow:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="financial.operativeCashflow" className="financial.operativeCashflow"><b>{getValue(formik.values.financial.operativeCashflow)}</b></label>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Accordion className="col-6">
                        <AccordionTab header="Energie">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <label htmlFor="energy.energyConsumption" className="energy.energyConsumption">Gesamter Energieverbrauch:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="energy.energyConsumption" className="energy.energyConsumption"><b>{getValue(formik.values.energy.energyConsumption)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="energy.nonRenewableEnergy" className="energy.nonRenewableEnergy">Anteil des Verbrauchs an nicht erneuerbarer Energie:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="energy.nonRenewableEnergy" className="energy.nonRenewableEnergy"><b>{getValue(formik.values.energy.nonRenewableEnergy)}</b></label>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Accordion className="col-6">
                        <AccordionTab header="Environmental, Social, Governance">
                            <div className="p-fluid grid formgrid">
                                <div className="field col-6">
                                    <label htmlFor="esg.biodiversity" className="esg.biodiversity">Biodiversität:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.biodiversity" className="esg.biodiversity"><b>{getValue(formik.values.esg.biodiversity)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.waterEmissions" className="esg.waterEmissions">Emissionen in Wasser:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.waterEmissions" className="esg.waterEmissions"><b>{getValue(formik.values.esg.waterEmissions)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.hazardousWaste" className="esg.hazardousWaste">Anteil gefährlicher Abfälle:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.hazardousWaste" className="esg.hazardousWaste"><b>{getValue(formik.values.esg.hazardousWaste)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.ungcPrincipals" className="esg.ungcPrincipals">Verstöße gegen UNGC Grundsätze:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.ungcPrincipals" className="esg.ungcPrincipals"><b>{getBool(formik.values.esg.ungcPrincipals)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.ungcMonitoring" className="esg.ungcMonitoring">Überwachung der UNGC Grundsätze:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.ungcMonitoring" className="esg.ungcMonitoring"><b>{getBool(formik.values.esg.ungcMonitoring)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.genderPayGap" className="esg.genderPayGap">Geschlechtsspezifisches Verdienstgefälle:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.genderPayGap" className="esg.genderPayGap"><b>{getValue(formik.values.esg.genderPayGap)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.boardGenderDiversity" className="esg.boardGenderDiversity">Geschlechtervielfalt in den Leitungs- und Kontrollorganen:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.boardGenderDiversity" className="esg.boardGenderDiversity"><b>{getValue(formik.values.esg.boardGenderDiversity)}</b></label>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="esg.controversialWeapons" className="esg.controversialWeapons">Umstrittene Waffen:</label>
                                </div>
                                <div className="field col-6 justify-content-right">
                                    <label htmlFor="esg.controversialWeapons" className="esg.controversialWeapons"><b>{getBool(formik.values.esg.controversialWeapons)}</b></label>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <br />
                {/* When we need to make it visible, then remove disabled, visible={false} and hidden parameters */}
                <div>
                    <span className="p-float-label">
                        {/* <InputText id="esgReportPath" name="esgReportPath" value={esgReportName} onChange={(e) => setESGReportName(e.target.value)} autoFocus tooltip="Geben Sie Ihrem PDF einen Namen" tooltipOptions={{ mouseTrack: true }} hidden visible={false}/> */}
                        <InputText id="esgReportPath" name="esgReportPath" value={esgReportName} onChange={(e) => setESGReportName(e.target.value)} autoFocus tooltip="Geben Sie Ihrem PDF einen Namen" tooltipOptions={{ mouseTrack: true }} />
                        <label htmlFor="esgReportPath" hidden>Report Namen*</label>
                        {/* <label htmlFor="esgReportPath">Report Namen*</label> */}
                    </span>
                </div>
                <div className="col-6">
                    {/* <Button label="Erstellen ESG Report" className="mt-0" type="button" onClick={generateESGReport} disabled visible={false}/> */}
                    <Button label="Erstellen ESG Report" className="mt-0" type="button" onClick={generateESGReport} />
                </div>
                <div className="col-6">
                    <Button label="Erstellen CO2" className="mt-0" type="button" onClick={generateCO2} />
                </div>
            </div>
        </div >
    )
}
import React, { useState } from 'react';
import "./ABSCalculator.css"

function ABSCalculator(props) {

  let abs_calculator_url = "https://app.powerbi.com/reportEmbed?reportId=db514561-d90f-45b2-9808-a667a73072ed&autoAuth=true&ctid=faf87534-ed1d-4742-84d2-5cedba6d215c"

  return (

    <div className="content-container" id="abs-calculator-container">
      {/* <div style={{ height:"100%", width:"100%"}}> */}
        <iframe  height="97%" width="96%" src="https://app.powerbi.com/reportEmbed?reportId=db514561-d90f-45b2-9808-a667a73072ed&autoAuth=true&ctid=faf87534-ed1d-4742-84d2-5cedba6d215c" frameBorder="0"
          style={{ position: "absolute", clipPath: "polygon(0% 0%,100% 0%,100% 95%,0% 95%)", bottom: -0+'px' }} allowFullScreen={true} ></iframe>
      {/* </div> */}
      {/* <TabView>
          {platform_analytics.map((item, index) => <TabPanel header={item['title']} key={index}>
            {!isNil(item['downloads']) &&
              // <div className='div-flexbox' key={index}>{Object.entries(item['downloads']).map((download_item, download_index) => <a href={download_item[1]} className='p-button'><b>{'Download '+ download_item[0]}</b></a>)}</div>}
              <div className='div-flexbox' key={index}>{Object.entries(item['downloads']).map((download_item, download_index) => <a href={download_item[1]} className='p-button'><b>{'Download ' + download_item[1].split(".").pop().toUpperCase()}</b></a>)}</div>}
            <iframe title={item['iframe_title']} width="100%" style={{ minHeight: "640px" }} height="100%" src={item['url']} frameBorder="0" allowFullScreen={true}></iframe>
          </TabPanel>)}
        </TabView> */}
    </div>
  )

};

export default ABSCalculator;


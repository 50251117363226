import React, { useEffect, useState  } from 'react';
import PlatformsOverview from '../Overview/platformOverview';
import { Panel } from 'primereact/panel';
import { Users } from '../Users/Users';
import axiosWithAuth from '../../../helpers/AxiosHelper';

const PlatformDashboard = (props) => {
    const data = [
      { id: 1, name: 'John Doe', email: 'johndoe@example.com', role: 'Admin' },
      { id: 2, name: 'Jane Smith', email: 'janesmith@example.com', role: 'User' },
      // Add more data as needed
    ];
    const setLoadingInProps = props.setLoading
    const [allUsers,setAllUsers] = useState(null)
    const [platformOverviewData, setPlatformOverviewData] = useState(null); // Set rowData to Array of Objects, one Object per Row

    useEffect(() => {
        setLoadingInProps(true)
        axiosWithAuth.get(`/platforms/users`).then((response) => {
            let data = response.data;
            console.log(data);
            setAllUsers(data);
            
        }).catch((err) => {
            alert(err);
            setLoadingInProps(false)
        })
        axiosWithAuth.get("/overview")
            .then(data => {
                console.log('Inside platform overview')
                setPlatformOverviewData(data.data)
            }).catch(err => {
                console.error(err)
            });
        setLoadingInProps(false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
    return (
    <div className='content-container'>
    <div style={{ maxWidth: "100%", padding: "1.2vw" }}>
      <div className="grid">
      <div className="col-12">
          <Panel header="Overview" toggleable >
          {platformOverviewData && <PlatformsOverview platformOverview={platformOverviewData}/>}
          </Panel>
        </div>

        <div className="col-8 col-offset-2">
          <Panel header="Users" toggleable >
            {allUsers && <Users users={allUsers}/>}
          </Panel>
        </div>
  
        
  
        {/* <div className="p-col-12 p-md-6">
          <Panel header="Actions">
            <Button label="Add User" className="p-button-primary" />
            <Button label="Delete User" className="p-button-danger" />
          </Panel>
        </div> */}
      </div>
      </div>
      </div>
    );
  };
  
  export default PlatformDashboard;
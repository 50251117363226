import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import "./platforms.css"
import React, { useState, useEffect } from 'react';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';
import axiosWithAuth from '../../helpers/AxiosHelper';
import { kebabCase } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import * as _ from 'lodash';
const emptyPlatform = {
    validate: false,
    spro_upload: false,
    map_data: false,
    data_types: [],
    platform_analytics: [],
    platform: '',
    _key: ''
}


export const Platforms = (props) => {
    const [platforms, setPlatforms] = useState(null);
    const [layout, setLayout] = useState('grid');
    const [newPlatformDialog, setNewPlatformDialog] = useState(false);
    const [newPlatform, setNewPlatform] = useState(emptyPlatform);
    const [submitted, setSubmitted] = useState(false);
    // const [sortKey, setSortKey] = useState(null);
    // const [sortOrder, setSortOrder] = useState(null);
    // const [sortField, setSortField] = useState(null);
    // const sortOptions = [
    //     {label: 'Price High to Low', value: '!price'},
    //     {label: 'Price Low to High', value: 'price'},
    // ];

    useEffect(() => {
        props.setLoading(true);
        axiosWithAuth.get("/platforms")
            .then(data => {
                console.log('Inside config')
                console.log(data)
                setPlatforms(data.data)
            }).catch(err => {
                console.error(err);
                alert(err);
            }).finally(()=>{
                props.setLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const onSortChange = (event) => {
    //     const value = event.value;

    //     if (value.indexOf('!') === 0) {
    //         setSortOrder(-1);
    //         setSortField(value.substring(1, value.length));
    //         setSortKey(value);
    //     }
    //     else {
    //         setSortOrder(1);
    //         setSortField(value);
    //         setSortKey(value);
    //     }
    // }

    const renderListItem = (data) => {
        return (
            <div className="col">
                <div className="platform-list">
                    <NavLink className='platform-link' to={kebabCase(data.platform)}  >
                        <Button style={{ "width": "100%" }}>{data.platform}</Button>
                    </NavLink>

                </div>
            </div>
        );
    }

    const renderGridItem = (data) => {
        return (
            <div className="col-12 md:col-4">
                <div className='platform-grid'>
                    <NavLink className='platform-link' to={kebabCase(data.platform)}  >
                    <Button className='p-button-lg platform-grid-button' label={data.platform} icon='pi pi-home'></Button>
                {/* <div className='platform-grid-button'>
                    
                       <span> {data.platform}</span>
                    
                    </div> */}
                    </NavLink>
                </div>
            </div>
        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }
    const openNew = () => {
        // setNewPlatform(emptyPlatform);
        setSubmitted(false);
        setNewPlatformDialog(true);
    }
    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6 col-offset-6" style={{ textAlign: 'right' }}>
                    <Button onClick={openNew}>Add New Platform</Button>
                </div>
            </div>
        );
    }


    const header = renderHeader();

    // New Platform Dialog stuff

    //TODO Add validation
    const validateNewPlatformData = (new_platform_data) => {
        if ((_.isNil(new_platform_data.platform)) || (_.isEmpty(new_platform_data.platform)))
            return false;
        
        for (const item of platforms){
            console.log(item)    
            if (item.platform === new_platform_data.platform)
                return false;
        }
        return true;
    }

    const onAddNewPlatform = () => {
        setSubmitted(true);
        if (validateNewPlatformData(newPlatform)) {
            props.setLoading(true);
            newPlatform['_key'] = generate_key(newPlatform.platform)
            axiosWithAuth.post("/platforms",newPlatform).then((response) => {
                let updated_data = response.data.data;
                console.log('New Platform Submitted');
                console.log(updated_data)
                return axiosWithAuth.get("/platforms");
            }).then(data => {
                console.log('Inside platforms')
                console.log(data)
                setPlatforms(data.data)
            }).catch(err => {
                console.error(err)
            }).finally(()=>{
                props.setLoading(false);
            });
            // setNewPlatform(emptyPlatform);
        }else{
            console.log('Validation Failed')
            alert('Platform with same name already Exists.')
        }
        setNewPlatform(emptyPlatform);
        hideDialog();

    }

    const generate_key = (platformName) => {
        return kebabCase(platformName)
        // return String(platformName).replace("")
    }

    const hideDialog = () => {
        // setNewPlatform(emptyPlatform);
        setSubmitted(false);
        setNewPlatformDialog(false);
    }
    const newPlatformDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={onAddNewPlatform} />
        </React.Fragment>
    );



    const MyToggleButton = ({
        label,
        name,
        value,
        handleChange,
        ...props
    }) => (<div className="field ">
        {/* <InputText id="name" name="name" value{formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} /> */}
        <label  htmlFor={name}>{label}</label >
        <ToggleButton id={name} name={name} checked={value} onIcon="pi pi-check" offIcon="pi pi-times" onChange={handleChange} {...props} />

    </div>);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _newPlatform = { ...newPlatform };
        _newPlatform[`${name}`] = val;
        setNewPlatform(_newPlatform);
    }


    return (
        <div className="content-container">
            <div style={{ maxWidth: "100%" }}>
                <DataView value={platforms} layout={layout} header={header} sortField="platform" sortOrder={1}
                    itemTemplate={itemTemplate} paginator rows={27} />
            </div>
            <Dialog visible={newPlatformDialog} style={{ width: '450px' }} header="Add a new Platform to Exaport" modal className="p-fluid" footer={newPlatformDialogFooter} onHide={hideDialog}>
                <div className="field ">
                    <span className="p-float-label">
                        <InputText required name='platform_name' value={newPlatform.platform} onChange={(e) => onInputChange(e, 'platform')}  className={classNames({ 'p-invalid block': submitted && !newPlatform.platform })} />
                        <label  htmlFor='platform_name'>Name of Platform</label>
                    </span>
                    {submitted && !newPlatform.platform && <small className="p-error">Platform Name is required.</small>}
                </div>
                <MyToggleButton label='Basic Validation' name="validate" value={newPlatform.validate} handleChange={(e) => onInputChange(e, 'validate')} />
                <MyToggleButton label='Upload to S-Pro' name="spro_upload" value={newPlatform.spro_upload} handleChange={(e) => onInputChange(e, 'spro_upload')}/>
                <MyToggleButton label='Map Data' name="map_data" value={newPlatform.map_data} handleChange={(e) => onInputChange(e, 'map_data')}/>
            </Dialog>
        </div>
    );
}

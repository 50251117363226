import { camelCase, startCase, upperFirst } from 'lodash';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Galleria } from 'primereact/galleria';
import { Panel } from 'primereact/panel';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosWithAuth from '../../../helpers/AxiosHelper';
import { PLATFORM_DETAIL_CONSUMER_COLUMNS, PLATFORM_DETAIL_HEADER_COLUMNS, PLATFORM_DETAIL_SME_COLUMNS } from "./platformOverviewColumns";

export const PlatformOverviewDetails = (props) => {
    const navigate = useNavigate();

    const { platformName } = useParams()
    const setLoadingInProps = props.setLoading
    const [currentPlatformDetails, setCurrentPlatformDetails] = useState(null)
    const [SMECharts, setSMECharts] = useState(null)
    const [CONSCharts, setCONSCharts] = useState(null)

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        setLoadingInProps(true)
        axiosWithAuth.get(`/overview/${platformName}`).then((response) => {
            let data = response.data;
            if (data[0].hasOwnProperty('SME') && data[0].hasOwnProperty('CONSUMER')) {
                let smeChartsData = [];
                let consChartsData = [];

                if (data[0]['SME']) {
                    smeChartsData = extractChartData(data[0]['SME']['exaloan_calculations'], true).concat(extractChartData(data[0]['SME']['platform_calculations'], false));
                }

                if (data[0]['CONSUMER']) {
                    consChartsData = extractChartData(data[0]['CONSUMER']['exaloan_calculations'], true).concat(extractChartData(data[0]['CONSUMER']['platform_calculations'], false));
                }

                setCurrentPlatformDetails(data);
                setSMECharts(smeChartsData);
                setCONSCharts(consChartsData);
                setLoadingInProps(false);
            } else {
                alert("SME or CONSUMER data not found.");
                setLoadingInProps(false);
            }
        }).catch((err) => {
            alert(err);
            setLoadingInProps(false)
        })
    }, []);

    const detailOverviewColumns = PLATFORM_DETAIL_HEADER_COLUMNS.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} />
    })

    const detailOverviewSMEColumns = PLATFORM_DETAIL_SME_COLUMNS.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} />
    })

    const detailOverviewCONSUMERColumns = PLATFORM_DETAIL_CONSUMER_COLUMNS.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable={col.sortable} />
    })

    const extractChartData = (data, isExaloan) => {
        let chartData = [];
        for (let chart in data) {
            if (chart !== 'recovery_rate') {
                let dataset = {
                    label: startCase(camelCase(chart)).concat(isExaloan ? ' (Exaloan)' : ' (Platform)'),
                    data: Object.values(data[chart]),
                    labels: Object.keys(data[chart])
                };
                chartData.push(dataset);
            }
        }
        return chartData;
    }

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: ['#1E9AB5', '#71C2BF', '#E0E1E3', '#0F1941', '#9BD5C3', '#2675C2', '#2C4ECD', '#077186', '#38EEFA']
    };

    return (
        <div className='content-container'>
            <div style={{ maxWidth: "100%", padding: "1.2vw" }}>
                <div className="grid nested-grid">
                    <div className="col-12">
                        <h2>
                            {upperFirst(camelCase(platformName))}
                        </h2>
                        <Panel >
                            <DataTable value={currentPlatformDetails} scrollable >
                                {detailOverviewColumns}
                            </DataTable>
                        </Panel>
                    </div>

                    <div className="col-12">
                        <div className="grid">
                            {(SMECharts !== null && SMECharts.length !== 0) && (<div className={(CONSCharts !== null && CONSCharts.length !== 0) ? "lg:col-6 md:col-12 sm:col-12" : "lg:col-12 md:col-12 sm:col-12"}>
                                <Panel header="SME Overview" toggleable >
                                    <div>
                                        <DataTable value={currentPlatformDetails} scrollable >
                                            {detailOverviewSMEColumns}
                                        </DataTable>
                                    </div>
                                    <div style={{ position: 'center' }}>
                                        <Galleria
                                            value={SMECharts}
                                            thumbnail={(chart) => (
                                                <Tag className="m-auto sm:mx-3" style={{ 'fontSize': '0.75em', 'textAlign': 'center' }} >{chart.label}</Tag>
                                            )}
                                            item={(chart) => (
                                                <div className="chart-container">
                                                    <Chart type="pie" data={{ labels: chart.labels, datasets: [{ data: chart.data }] }} options={chartOptions} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </Panel>
                            </div>)}
                            {(CONSCharts !== null && CONSCharts.length !== 0) && (<div className={(SMECharts !== null && SMECharts.length !== 0) ? "lg:col-6 md:col-12 sm:col-12" : "lg:col-12 md:col-12 sm:col-12"}>
                                <Panel header="CONSUMER Overview" toggleable >
                                    <div>
                                        <DataTable value={currentPlatformDetails} scrollable>
                                            {detailOverviewCONSUMERColumns}
                                        </DataTable>
                                    </div>
                                    <div style={{ position: 'center' }}>
                                        <Galleria
                                            value={CONSCharts}
                                            thumbnail={(chart) => (
                                                <Tag className="m-auto sm:mx-3" style={{ 'fontSize': '0.75em', 'textAlign': 'center' }}>{chart.label}</Tag>
                                            )}
                                            item={(chart) => (
                                                <div className="chart-container">
                                                    <Chart type="pie" data={{ labels: chart.labels, datasets: [{ data: chart.data }] }} options={chartOptions} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </Panel>
                            </div>)}
                        </div>
                    </div>
                    <div>*in EUR</div>
                </div>
            </div>
            <div className='col-1'>
                <Button id='go-back-simon' type='button' icon="pi pi-arrow-left" onClick={goBack} />
            </div>
        </div>
    );
}

export default PlatformOverviewDetails;
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import template_consumer from '../../assets/xlsx/Loanbook Template - Consumer.xlsx';
import template_sme from '../../assets/xlsx/Loanbook Template - SME.xlsx';
import template_ecs from '../../assets/xlsx/Portfolio Template - ECS.xlsx';
import template_cash_flows from '../../assets/xlsx/Cash Flows Template.xlsx';
import template_opportunities from '../../assets/xlsx/Opportunities Template.xlsx';
import info_consumer from '../../assets/pdf/Information Sheet - Consumer.pdf';
import info_sme from '../../assets/pdf/Information Sheet - SME.pdf';
import info_ecs from '../../assets/pdf/Information Sheet - ECS.pdf';
import info_cash_flows from '../../assets/pdf/Information Sheet - Cash Flows.pdf';
import info_opportunities from '../../assets/pdf/Information Sheet - Opportunities.pdf';
import user_guide_sme from '../../assets/pdf/User Guide - SME.pdf';
import user_guide_consumer from '../../assets/pdf/User Guide - Consumer.pdf';
import user_guide_upload from '../../assets/pdf/User Guide - File Upload.pdf';
import "./file_upload.css";

const infoSheets = [
  { 'label': 'Information Sheet - SME', 'url': info_sme, 'data_type': 'SME' },
  { 'label': 'Information Sheet - Consumer', 'url': info_consumer, 'data_type': 'CONSUMER' },
  { 'label': 'Information Sheet - ECS', 'url': info_ecs, 'data_type': 'ECS' },
  { 'label': 'Information Sheet - Cash Flows', 'url': info_cash_flows, 'data_type': 'CASH_FLOWS' },
  { 'label': 'Information Sheet - Opportunities', 'url': info_opportunities, 'data_type': 'OPPORTUNITIES' },
];

const templates = [
  { 'label': 'Loanbook Template - SME', 'url': template_sme, 'data_type': 'SME' },
  { 'label': 'Loanbook Template - Consumer', 'url': template_consumer, 'data_type': 'CONSUMER' },
  { 'label': 'Portfolio Template - ECS', 'url': template_ecs, 'data_type': 'ECS' },
  { 'label': 'Cash Flows Template', 'url': template_cash_flows, 'data_type': 'CASH_FLOWS' },
  { 'label': 'Opportunities Template', 'url': template_opportunities, 'data_type': 'OPPORTUNITIES' },
];

const userGuides = [
  { 'label': 'User Guide - SME', 'url': user_guide_sme, 'data_type': 'SME' },
  { 'label': 'User Guide - Consumer', 'url': user_guide_consumer, 'data_type': 'CONSUMER' },
];

const basicUploadUserGuide = [
  { 'label': 'User Guide - File Upload', 'url': user_guide_upload },
]

function Slidein(props) {
  const menu_template = useRef(null);
  const menu_info_sheet = useRef(null);
  const menu_user_guide = useRef(null);
  const data_types = props.data_types;
  const filtered = (value) => data_types.includes(value.data_type);
  const empty_user_guides = userGuides.filter(filtered).length === 0;

  return (
    <div className='grey-out2'>
      <div className="flex align-items-center justify-content-center flex-wrap card-container gap-2">
        <p className='text6'>Take a look at our information sheets, templates or user guides.</p>
        <Menu model={infoSheets.filter(filtered)} popup ref={menu_info_sheet} id="info_sheet_menu" />
        <Button label="Information Sheets" icon="pi pi-bars" onClick={(event) => menu_info_sheet.current.toggle(event)} aria-controls="info_sheet_menu" aria-haspopup className="p-button-raised p-button-rounded p-button-sm" />
        <Menu model={templates.filter(filtered)} popup ref={menu_template} id="template_menu" />
        <Button label="Templates" icon="pi pi-bars" onClick={(event) => menu_template.current.toggle(event)} aria-controls="template_menu" aria-haspopup className="p-button-raised p-button-rounded p-button-sm" />
        {empty_user_guides ? (
          <div>
            <Menu model={basicUploadUserGuide} popup ref={menu_user_guide} id="user_guide_menu" />
            <Button label="User Guides" icon="pi pi-bars" onClick={(event) => menu_user_guide.current.toggle(event)} aria-controls="user_guide_menu" aria-haspopup className="p-button-raised p-button-rounded p-button-sm" />
          </div>
        ) : (
          <div>
            <Menu model={userGuides.filter(filtered)} popup ref={menu_user_guide} id="user_guide_menu" />
            <Button label="User Guides" icon="pi pi-bars" onClick={(event) => menu_user_guide.current.toggle(event)} aria-controls="user_guide_menu" aria-haspopup className="p-button-raised p-button-rounded p-button-sm" />
          </div>
        )}
      </div>
    </div>
  );

};

export default Slidein;
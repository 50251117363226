import * as Yup from 'yup';

export const formStructure = {
    formValues: {
        address: {
            companyName: '',
            street: '',
            houseNumber: '',
            postCode: '',
            city: ''
        },
        information: {
            headquarters: '',
            otherLocations: '',
            foundedIn: '',
            naceCode: '',
            employees: '',
            districtCourt: '',
            registrationNumber: ''
        },
        financial: {
            balanceSheetDate: '',
            assets: '',
            revenue: '',
            equityCapital: '',
            financialDebt: '',
            ebitda: '',
            ebit: '',
            profit: '',
            ebitdaMargin: '',
            indebtedness: '',
            operativeCashflow: ''
        },
        energy: {
            energyConsumption: '',
            nonRenewableEnergy: ''
        },
        esg: {
            biodiversity: '',
            waterEmissions: '',
            hazardousWaste: '',
            ungcPrincipals: false,
            ungcMonitoring: false,
            genderPayGap: '',
            boardGenderDiversity: '',
            controversialWeapons: false
        }
    },
    formValidations:
        Yup.object({
            address: Yup.object({
                companyName: Yup.string().required('Erforderlich'),
                street: Yup.string().required('Erforderlich'),
                houseNumber: Yup.string().required('Erforderlich'),
                postCode: Yup.string().required('Erforderlich'),
                city: Yup.string().required('Erforderlich')
            }),
            information: Yup.object({
                headquarters: Yup.string().required('Erforderlich'),
                otherLocations: Yup.array().of(Yup.string()),
                foundedIn: Yup.number().integer(),
                naceCode: Yup.string(),
                employees: Yup.number().integer(),
                districtCourt: Yup.string().required('Erforderlich'),
                registrationNumber: Yup.string().required('Erforderlich')
            }),
            financial: Yup.object({
                balanceSheetDate: Yup.date().required('Erforderlich'),
                assets: Yup.number().required('Erforderlich'),
                revenue: Yup.number(),
                equityCapital: Yup.number(),
                financialDebt: Yup.number(),
                ebitda: Yup.number(),
                ebit: Yup.number(),
                profit: Yup.number(),
                ebitdaMargin: Yup.number(),
                indebtedness: Yup.number(),
                operativeCashflow: Yup.number()
            }),
            energy: Yup.object({
                energyConsumption: Yup.number(),
                nonRenewableEnergy: Yup.number()
            }),
            esg: Yup.object({
                biodiversity: Yup.number(),
                waterEmissions: Yup.number(),
                hazardousWaste: Yup.number(),
                ungcPrincipals: Yup.bool(),
                ungcMonitoring: Yup.bool(),
                genderPayGap: Yup.number(),
                boardGenderDiversity: Yup.number(),
                controversialWeapons: Yup.bool()
            })
        })
}